import React, {
    useEffect,
    useContext,
    useState,
} from 'react';
import {
    NavLink,
    Redirect,
    useHistory
} from 'react-router-dom';
import Homebar from './Homebar';
import Copyright from './Copyright';
import {
    Page,
    PageContainer,
    PageContainerCards,
    PageCard,
    Title,
    Subtitle
} from './StylesComponents/common-styled';
import {MyContext} from './Context';
import "./components.css";


const HomeUser = ({session}) => {
    
    //console.log("Secion",session.obtenerUsuario)
    const history=useHistory();
    const [userOutlook,setUserOutlook]=useState(false);
    const [redirect,useRedirect]=useState(false);
    let nameUser = (session.obtenerUsuario) ? `${session.obtenerUsuario.cEMP_Nombre}` : <Redirect to = '/'/>
    const context=useContext(MyContext)
        const navigation = [{
            title: nameUser,
            route: ''
        }]
    console.log(context);
    const loginOutlook=()=>{
        context.login()
            .then((data)=>history.push('/secciones/reuniones'))
            .catch((error)=>console.log('error=====>>>>',error))
    }
    useEffect(() => {
            let user=JSON.parse(localStorage.getItem('user'))
            const date=new Date()
            console.log(date)
            if(user!==null && new Date(user.expiresOn)>=date){
                user={AuthComponentProps:{
                    ...user
                }}
                context.getDataAuthenticated(user);
                context.getDataAuthenticated(user);
                setUserOutlook(user.AuthComponentProps.isAuthenticated);
            }
            else{
                setUserOutlook(context.state.AuthComponentProps.isAuthenticated);
            }
    }, []);
    return ( 
        <Page >
            <Homebar navigation = {navigation}/> 
            <PageContainer>
                <Title > Bienvenido </Title> 
                <Subtitle > Ingresa a la sección deseada para comenzar </Subtitle> 
            </PageContainer> 
            <PageContainerCards>
                <PageCard> 
                    {userOutlook?
                        <NavLink exact to = "/secciones/reuniones" >
                            <img src = "/images/reuniones.png" alt = "imagew" width = {68}/> 
                        </NavLink>:
                        <img src = "/images/reuniones.png" alt = "imagew" width = {68} onClick={loginOutlook}/> 
                    }
                    <h3> Reuniones </h3> 
                    <p> Agenda tu cita o viaje para seguimiento y mantenimiento de las relaciones con cedentes y brókers. </p> 
                </PageCard>
                <PageCard>
                    <NavLink exact to='/secciones/viaticos'>
                        <img src = "/images/Viaticos500.png" alt = "imagew" width = {68}/> 
                    </NavLink>
                    
                    <h3> Viáticos </h3> 
                    <p> Revisa el expediente de los cedentes y brókers, ¡Todo en un solo lugar! <b> (Próximamente) </b></p>
                </PageCard>  
                <PageCard>
                    <img src = "/images/snapshot500.png" alt = "imagew" width = {68}/> 
                    <h3> Snapshot </h3> 
                    <p> Revisa el expediente de los cedentes y brókers, ¡Todo en un solo lugar! <b> (Próximamente) </b></p>
                </PageCard> 
                <PageCard>
                    <img src = "/images/suscripción_500-01.png" alt = "imagew" width = {68}/> 
                    <h3> Suscripción y siniestros </h3> 
                    <p> Realiza el estatus de los procesos de suscripción y siniestros activos. <b> (Próximamente) </b></p>
                </PageCard> 
                <PageCard>
                    <img src = "/images/acuerdos500.png" alt = "imagew" width = {68}/> 
                    <h3> Acuerdos de viaje </h3> 
                    <p> Una vez concluido tu viaje registra los acuerdos y dales seguimiento. <b> (Próximamente) </b></p>
                </PageCard> 
            </PageContainerCards> 
            <Copyright color = "#222" backgroundColor = "#fff">
                <img src = "/images/logo_patria.png" alt = "logo_patria" width = {68}/>
            </Copyright> 
        </Page>
    )
}

export default HomeUser;