const graph = require('@microsoft/microsoft-graph-client');

const getAuthenticatedClient=(accessToken)=>{
    const client=graph.Client.init({
        authProvider:(done)=>{
            done(null,accessToken);
        }
    });
    return client;
}

export const getUserDetails=async(accessToken)=>{
    const client=getAuthenticatedClient(accessToken);

    const user=await client
        .api('/me')
        .select('displayName,mail,mailboxSettings,userPrincipalName')
        .get();
    return user;
}

export const createEvent=async(accessToken,newEvent)=>{
    const client = getAuthenticatedClient(accessToken);

    return await client
        .api('me/events')
        .post(newEvent);
}

export const updateEvent=async(accessToken,newEvent,idEvent)=>{
    const client=getAuthenticatedClient(accessToken);

    return await client
        .api(`me/events/${idEvent}`)
        .update(newEvent);
}

export const deleteEvent=async(accessToken,idEvent)=>{
    const client=getAuthenticatedClient(accessToken);

    return await client
        .api(`me/events/${idEvent}`)
        .delete();
}

export const getEvent=async(accessToken,idEvent)=>{
    const client=getAuthenticatedClient(accessToken);

    return await client
        .api(`me/events/${idEvent}`)
        .select('attendees')
        .get();
}