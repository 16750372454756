export const config = {
    appId: '43a96c38-265d-4a69-b6cc-cc8dbf4c6087',
    //redirectUri: 'http://localhost:3000/secciones',
    redirectUri: 'https://sandbox-patria.alimx.mx/secciones/reuniones',
    scopes: [
      'user.read',
      'mailboxsettings.read',
      'calendars.readwrite'
    ]
  };
  