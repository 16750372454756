import React from 'react';
import { ContainerTwoInputs } from '../StylesComponents/common-styled';
import {  Typography } from '@material-ui/core';


const FormTypography=({text,children})=>{
  return(
    <ContainerTwoInputs flexDirection='column'>
    <Typography>{text}</Typography>
      {children}
    </ContainerTwoInputs>
  );
}

export default FormTypography;