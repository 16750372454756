import React, { useState, useEffect } from 'react';
import {AcordeonContainerBody,ContainerTwoInputs} from '../StylesComponents/common-styled';
import { TextField } from '@material-ui/core';
import FormTypography from './FormsTypography';
import { AddCircle } from '@material-ui/icons';
import { green } from '@material-ui/core/colors';

const InputTravel=({placeholder,name,onChange,id,value})=>{
  return (
    <TextField fullWidth variant='outlined' placeholder={placeholder} name={name} onChange={onChange} id={id} value={value}/>
  );
};

const useFormMateriales=({title,text1,text2,text3,cambio})=>{
  const [papeleria,setPapeleria]=useState([{title:''}]);
  const [promocional,setPromocional]=useState([{title:''}]);
  const [requerimiento,setRequerimiento]=useState([{title:''}]);

  const [materiales,setMateriales]=useState(
    [
      {
        cMAT_Descripcion:'',
      }
    ]
  );
  const [requerimientoTI,setRequerimientoTI]=useState(
    [
      {
        cREQT_Descripcion:'',
      }
    ]
  );

  const [promocionales,setPromocionales]=useState(
    [
      {
        cPRO_Descripcion:'',
      }
    ]
  );

  const addPapeleria=()=>{
    setPapeleria([...papeleria,{title:''}]);
    setMateriales([...materiales,{cMAT_Descripcion:''}]);
  }
  const addPromocional=()=>{
    setPromocional([...promocional,{title:''}]);
    setPromocionales([...promocionales,{cPRO_Descripcion:''}])
  }
  const addRequerimiento=()=>{
    setRequerimiento([...requerimiento,{title:''}]);
    setRequerimientoTI([...requerimientoTI,{cREQT_Descripcion:''}]);
  }
  const onChanguePape=(e)=>{
    setMateriales(materiales.map((data,index)=>{
      if(index==e.target.id){
        data={...data,[e.target.name]:e.target.value}
      }
      return data;
    }))
    sessionStorage.setItem('materials',JSON.stringify(materiales.map((data,index)=>{
      if(index==e.target.id){
        data={...data,[e.target.name]:e.target.value}
      }
      return data;
    })))
  }

  const onChangueProm=(e)=>{
    setPromocionales(promocionales.map((data,index)=>{
      if(index==e.target.id){
        data={...data,[e.target.name]:e.target.value}
      }
      return data;
    }))
    sessionStorage.setItem('promotionals',JSON.stringify(promocionales.map((data,index)=>{
      if(index==e.target.id){
        data={...data,[e.target.name]:e.target.value}
      }
      return data;
    })))
  }

  const onChangueReq=(e)=>{
    setRequerimientoTI(requerimientoTI.map((data,index)=>{
      if(index==e.target.id){
        data={...data,[e.target.name]:e.target.value}
      }
      return data;
    }))
    sessionStorage.setItem('ITrequiremen',JSON.stringify(requerimientoTI.map((data,index)=>{
      if(index==e.target.id){
        data={...data,[e.target.name]:e.target.value}
      }
      return data;
    })))
  }

  useEffect(()=>{
    setMateriales(
      [
        {
          cMAT_Descripcion:'',
        }
      ]
    );
    setRequerimientoTI(
      [
        {
          cREQT_Descripcion:'',
        }
      ]
    );
  
    setPromocionales(
      [
        {
          cPRO_Descripcion:'',
        }
      ]
    );
    setPapeleria([{title:''}]);
    setPromocional([{title:''}]);
    setRequerimiento([{title:''}]);
  },[cambio])
  return(
    <>
      <div style={{marginTop:'20px'}}>
        <h3 style={{textAlign:'center'}}>{title}</h3>
      </div>
      <AcordeonContainerBody flexDirection='column'>
        <AcordeonContainerBody width='100%' flexWrap='wrap'>
          {papeleria.map((papel,index)=>
            <FormTypography text={text1 || 'Materiales y/o papelería'}>
              <InputTravel placeholder='Ej, Pizarrón, papeleria, Tag, etc.' name='cMAT_Descripcion' id={index} onChange={onChanguePape} value={materiales[index].cMAT_Descripcion}/>
            </FormTypography>
          )}
        </AcordeonContainerBody>
        <ContainerTwoInputs width='15%'>
          <AddCircle style={{color:green[500]}} onClick={addPapeleria}/>
          <p>Agregar material</p>
        </ContainerTwoInputs>
      </AcordeonContainerBody>
      <AcordeonContainerBody flexDirection='column'>
        <AcordeonContainerBody width='100%' flexWrap='wrap'>
          {promocional.map((prom,index)=>
            <FormTypography text={text2 || 'Promocionales'}>
              <InputTravel placeholder='Ingresa el tipo de promocional' name='cPRO_Descripcion' id={index} onChange={onChangueProm} value={promocionales[index].cPRO_Descripcion}/>
            </FormTypography>
          )}
        </AcordeonContainerBody>
        <ContainerTwoInputs width='15%'>
          <AddCircle style={{color:green[500]}} onClick={addPromocional}/>
          <p>Agregar material</p>
        </ContainerTwoInputs>
      </AcordeonContainerBody>
      <AcordeonContainerBody flexDirection='column'>
        <AcordeonContainerBody width='100%' flexWrap='wrap'>
          {requerimiento.map((req,index)=>
            <FormTypography text={text3 || 'Requerimiento TI'}>
              <InputTravel placeholder='Paquete celular, cañon, proyector, etc.' name='cREQT_Descripcion' id={index} onChange={onChangueReq} value={requerimientoTI[index].cREQT_Descripcion}/>
            </FormTypography>
          )}
        </AcordeonContainerBody>
        <ContainerTwoInputs width='15%'>
          <AddCircle style={{color:green[500]}} onClick={addRequerimiento}/>
          <p>Agregar material</p>
        </ContainerTwoInputs>
      </AcordeonContainerBody>
    </>
  )
}

export default useFormMateriales;