import {gql} from '@apollo/client';

export const AUTENTICATE_USER = gql`
    mutation autenticarUsuario($user:String!,$password:String!){
        autenticarUsuario(user:$user,password:$password){
            status
            token
            message,
            userName
        }
    }`;

export const CREATE_EVENT=gql`
mutation createEvent(
  $cEMP_Id:Int!,
  $cEDO_Id:Int!,
  $cPAI_Id:Int!,
  $cPLA_Id:Int,
  $cREU_ConferenciaURL:String,
  $cREU_FechaInicio:String!,
  $cREU_HoraInicio:String!,
  $cREU_FechaFin:String!,
  $cREU_HoraFin:String!,
  $cREU_Lugar:String!,
  $cREU_Titulo:String!,
  $cREU_ZonaHoraria:String!
  $cREU_FechaActualPais:String!,
  $cREU_HoraActualPais:String!,
  $cREU_TipoReunion:String!,
  $cREU_IdOutlook:String!
  $cCIUD_Id:Int!){
    createEvents(
        cEMP_Id:$cEMP_Id,
        cEDO_Id:$cEDO_Id,
        cPAI_Id:$cPAI_Id,
        cPLA_Id:$cPLA_Id,
        cREU_ConferenciaURL:$cREU_ConferenciaURL,
  	    cREU_FechaInicio:$cREU_FechaInicio,
  	    cREU_HoraInicio:$cREU_HoraInicio,
        cREU_FechaFin:$cREU_FechaFin,
        cREU_HoraFin:$cREU_HoraFin,
        cREU_Lugar:$cREU_Lugar,
        cREU_Titulo:$cREU_Titulo,
        cREU_ZonaHoraria:$cREU_ZonaHoraria,
        cREU_FechaActualPais:$cREU_FechaActualPais,
  	    cREU_HoraActualPais:$cREU_HoraActualPais,
        cREU_TipoReunion:$cREU_TipoReunion,
        cREU_IdOutlook:$cREU_IdOutlook
        cCIUD_Id:$cCIUD_Id){
            status,
            message,
            cREU_Id
    }
}`;

export const CREATE_EVENTINVITADOS=gql`
    mutation  createEventsInvitado($cREU_Id:Int!,$cEMP_Id:Int!){
        createEventsInvitados(cREU_Id:$cREU_Id,cEMP_Id:$cEMP_Id){
            status,
            message
  }
}
`;

export const CREATE_EVENTCORREDOR=gql`
    mutation createEventsCorredor($cREU_Id:Int,$cCOR_Id:Int,$cCOR_Version:Int){
        createEventsCorredor(cREU_Id:$cREU_Id,cCOR_Id:$cCOR_Id,cCOR_Version:$cCOR_Version){
            status,
            message
        }
    }`;

export const CREATE_EVENTRETROCESIONARIO=gql`
    mutation createEventsRetrocesionario($cREU_Id:Int,$cRET_Id:Int,$cRET_Version:Int){
        createEventsRetrocesionario(cREU_Id:$cREU_Id,cRET_Id:$cRET_Id,cRET_Version:$cRET_Version){
            status,
            message
        }
    }`;

export const CREATE_EVENTCOMPANY=gql`
    mutation createEventsCompany($cREU_Id:Int,$cCIA_Id:Int,$cCIA_Version:Int){
        createEventsCompany(cREU_Id:$cREU_Id,cCIA_Id:$cCIA_Id,cCIA_Version:$cCIA_Version){
            status,
            message
        }
    }`;

export const CREATE_EVENTEJECUTIVO=gql`
    mutation createEventsEjecutivo($cREU_Id:Int,$cTORG_Id:Int,$cORG_Id:Int,$cORG_Ver:Int,$cEje_Id:Int){
        createEventsEjecutivo(cREU_Id:$cREU_Id,cTORG_Id:$cTORG_Id,cORG_Id:$cORG_Id,cORG_Ver:$cORG_Ver,cEje_Id:$cEje_Id){
            status,
            message
        }
    }`;

export const DELETE_EVENT=gql`
    mutation deleteEvent($cREU_Id:Int){
        deleteEvents(cREU_Id:$cREU_Id){
            status,
            message
        }
    }`;

export const UPDATE_EVENT=gql`
    mutation UpdateEvent(
    $cREU_Id:Int!,
    $cEMP_Id:Int!,
    $cEDO_Id:Int!,
    $cPAI_Id:Int!,
    $cPLA_Id:Int!,
    $cREU_ConferenciaURL:String,
    $cREU_FechaInicio:String!,
    $cREU_HoraInicio:String!,
    $cREU_FechaFin:String!,
    $cREU_HoraFin:String!,
    $cREU_Lugar:String!,
    $cREU_Titulo:String!,
    $cREU_ZonaHoraria:String!,
    $cREU_FechaActualPais:String!,
    $cREU_HoraActualPais:String!,
    $cREU_TipoReunion:String!
    ){
  updateEvents(
    cREU_Id:$cREU_Id,
    cEMP_Id:$cEMP_Id,
    cEDO_Id:$cEDO_Id,
    cPAI_Id:$cPAI_Id,
    cPLA_Id:$cPLA_Id,
    cREU_ConferenciaURL:$cREU_ConferenciaURL,
  	cREU_FechaInicio:$cREU_FechaInicio,
  	cREU_HoraInicio:$cREU_HoraInicio,
    cREU_FechaFin:$cREU_FechaFin,
    cREU_HoraFin:$cREU_HoraFin,
    cREU_Lugar:$cREU_Lugar,
    cREU_Titulo:$cREU_Titulo,
    cREU_ZonaHoraria:$cREU_ZonaHoraria,
    cREU_FechaActualPais:$cREU_FechaActualPais,
  	cREU_HoraActualPais:$cREU_HoraActualPais,
  	cREU_TipoReunion:$cREU_TipoReunion
  ){
    status,
    message,
  }
}`;

export const UPDATE_INVITADOS=gql`
    mutation updateInvitado($cREU_Id:Int!,$cEMP_Id:Int,$rREUXEMP_Status:String){
        updateEventsInvitados(cREU_Id:$cREU_Id,cEMP_Id:$cEMP_Id,rREUXEMP_Status:$rREUXEMP_Status){
            status,
            message
        }
    }`;

export const SAVE_VIATICOS = gql`
    mutation createTravelExpenses($input: TravelExpenses) {
        createTravelExpenses(input:$input) {
            status
            cVIT_Id
        }
    }
`;