import React from 'react';
import {AcordeonContainerBody} from '../StylesComponents/common-styled';
import FormTypography from './FormsTypography';
import { TextField } from '@material-ui/core';

const InputTravel=({placeholder,name,onChange,id,value,type})=>{
  return (
    <TextField fullWidth variant='outlined' placeholder={placeholder} name={name} onChange={onChange} id={id} value={value} type={type}/>
  );
};


const FormAlojamiento=({title,text1,text2,text3,text4,id,handleChangue,valueInputs})=>{
  return(
    <>
      <div style={{marginTop:'20px'}}>
        <h3 style={{textAlign:'center'}}>{title}</h3>
      </div>
      <AcordeonContainerBody>
        <FormTypography text={text1 || 'Hotel'}>
          <InputTravel placeholder='Ingresa el nombre del hotel' name='hotel' onChange={handleChangue} id={id} value={valueInputs[id].hotel}/>
        </FormTypography>
        <FormTypography text={text2 || 'URL de cotización'}>
          <InputTravel placeholder='Ingresa la url de cotización' name='urlCotizacion' onChange={handleChangue} id={id} value={valueInputs[id].urlCotizacion}/>
        </FormTypography>
      </AcordeonContainerBody>
      <AcordeonContainerBody>
        <FormTypography text={text3 || 'Fecha de alojamiento'}>
          <InputTravel placeholder='Ingresa la fecha de alojamiento' name='fecha' onChange={handleChangue} id={id} type='date' value={valueInputs[id].fecha}/>
        </FormTypography>
        <FormTypography text={text4 || 'Precio de alojamiento'}>
          <InputTravel placeholder='Ingresa el precio de alojamiento' name='precio' onChange={handleChangue} id={id} value={valueInputs[id].precio}/>
        </FormTypography>
      </AcordeonContainerBody>
      </>
  )
}

export default FormAlojamiento;