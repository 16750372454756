import React from 'react';
import revision from '../../assets/images/icons/revision.svg';
import avion2 from '../../assets/images/icons/avion2.svg';
const CardProgress=({status,idViaticos,nombre,idReunion,fechas,pais})=>{
  return(
    <div style={{width:'337px',border:'1px solid #e5e5e5:borderRadius:5px',boxShadow: '0px 4px 4px rgba(0,0,0,0.25)',display:'flex',justifyContent:'center',flexDirection:'column',alignItems:'center',marginBottom:'20px'}}>
      <div style={{width:'95%',height:'32px', borderBottom:'1px solid #C8C8C8',display:'flex'}}>
        <div style={{width:'50%',display:'flex',alignItems:'end'}}>
          <p style={{marginRight:'10px'}}><strong>En Revisión</strong></p>
          <img src={revision} width='21px' height='21px' alt='alert'/>
        </div>
        <div style={{width:'50%',display:'flex',alignItems:'end',justifyContent:'flex-end'}}>
          <p><strong>{`No. ${idViaticos}`}</strong></p>
        </div>
      </div>
      <div style={{width:'95%',display:'flex',justifyContent:'center'}}>
        <div style={{width:'60%'}}>
          {nombre}<br/>
          {`Reunión No.${idReunion}`}<br/>
          {fechas}<br/>
          {pais}<br/>
          <p style={{textAlign:'center',color:'#3898EC'}}>Ver Detalles</p>
        </div>
        <div style={{width:'40%'}}>
        <img src={avion2} width='100%' alt='avion'/>
        </div>
      </div>
    </div>
  )
}
export default CardProgress;