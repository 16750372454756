import React from 'react';
import {ButtonStyledNavigation} from '../StylesComponents/common-styled';

const ButtonNavigation=({img,text,backgroundColor,border,color,onClick,width,justifyContent})=>{
  return(
    <ButtonStyledNavigation borderRadius='5px' width={width || "100%"} alingItems="center" backgroundColor={backgroundColor} border={border} color={color} justifyContent={justifyContent} onClick={onClick}>
    {img?<img src={img} alt='documents' style={{width:'90px'}}/>:null}
        {text}
    </ButtonStyledNavigation>
  )
}

export default ButtonNavigation;