import React, { useState, useEffect, useContext } from 'react';
import { NavLink, Redirect } from "react-router-dom"
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import Homebar from './Homebar';
import Copyright from './Copyright';
import IconRemove from './IconRemove';
import IconEdit from './IconEdit';
import DeleteEvent from './DeleteEvent';
import Diaolog from './Dialog';
import DiakigView from './DialogView';
import { Page, PageContainer, Title, Subtitle } from './StylesComponents/common-styled';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, makeStyles, withStyles } from '@material-ui/core';
import { useLazyQuery, useMutation, Query, useQuery } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import VerticalAlignTopIcon from '@material-ui/icons/VerticalAlignTop';
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';
import VisibilityIcon from '@material-ui/icons/Visibility';
import "./components.css";
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';

import FilterListIcon from '@material-ui/icons/FilterList';



import { GET_CALENDAREVENTS, GET_FECHASEVENTS, GET_REUNION } from '../query/query';
import { DELETE_EVENT } from '../mutation/mutation';
import {deleteEvent,getEvent} from '../services/GraphService'

import { MyContext } from './Context';
import useFilterTable from '../helpers/hooks/useFilterTable';
import DialogView from './DialogView';


require('moment/locale/es.js');

const localizer = momentLocalizer(moment);

const StyledTableCell = withStyles(() => ({
    head: {
        backgroundColor: "#DDDDDD",
        color: "#333333",
        textAlign: "center",
    }
}))(TableCell);

const useStyles = makeStyles({
    table: {
        width: "100%",
    },
    body: {
        fontSize: 14,
    },
    container: {
        width: "90%",
        marginBottom: "490px",
    },
})


const navigation = [{
    title: 'Reuniones',
    route: '/secciones/reuniones'
},
{
    title: 'Snapshot',
    route: '/secciones/snapshots'
},
{
    title: 'Suscripción',
    route: '/secciones/suscripcion-siniestro'
},
{
    title: 'Acuerdos',
    route: '/secciones/acuerdos-viaje'
},
{
    title: 'Secciones',
    route: '/secciones'
},
]


const Reuniones = ({ session, history }) => {
    const context = useContext(MyContext);
    const handleDataFilter = (data) => {
        console.log("UPdate");
        setDataTable(data)
    }
    const [getEvents] = useLazyQuery(GET_CALENDAREVENTS, {
        fetchPolicy: 'network-only',
        onCompleted({ getCalendarEvents }) {
            console.log("DATA", getCalendarEvents)
            setDataTable(getCalendarEvents)
        }
    })
    const { dataTable, statusFilter, setDataTable, actions } = useFilterTable();
    const [remove, setRemove] = useState(false);
    const [attendees,setAttendees]=useState(null);
    const [cerrar,setCerrar]=useState(false);
    const [title,setTitle]=useState('');

    const handleCloseRemove = () => {
        sessionStorage.clear();
        setRemove(false);
    }

    const handleOpenRemove = (cREU_Id, cREU_IdOutlook) => {
        setRemove(true);
        sessionStorage.setItem('cREU_Id', cREU_Id);
        sessionStorage.setItem('cREU_IdOutlook', cREU_IdOutlook);
    }

    const handleViewEvent=async(cREU_Id,cREU_IdOutlook)=>{
        await getEvent(context.state.AuthComponentProps.token,cREU_IdOutlook).then(({attendees})=>{
            setAttendees(attendees)
            setTitle(cREU_Id);
            console.log(attendees)
            setCerrar(true);
        })

    }

    const handleEdit = async (cREU_Id) => {
        await getReuniones({ variables: { cREU_Id } })
    }

    const deleteEvento =async() => {
        deleteEventoBD({
            variables: {
                cREU_Id: parseInt(sessionStorage.getItem('cREU_Id'), 10)
            }
        });
        await deleteEvent(context.state.AuthComponentProps.token,sessionStorage.getItem('cREU_IdOutlook'))
        .then((data)=>{
            console.log(data)
            setRemove(false)
            history.push('/secciones/reuniones/')
        })
        .catch((err)=>console.log('Erroor======>',err));
    }

    let nameUser = (session.obtenerUsuario) ? null : history.push('/')
    let idUser = (session.obtenerUsuario) ? session.obtenerUsuario.cEMP_Id : null

    const [getReuniones] = useLazyQuery(GET_REUNION, {
        onCompleted({ getReunion }) {
            context.getData(getReunion)
            setTimeout(() => {
                history.push('/secciones/reuniones/programar-reunion');
            }, 800)
        }
    })



    const [deleteEventoBD] = useMutation(DELETE_EVENT, {
        onCompleted({ deleteEvents }) {
        }
    })


    useEffect(() => {
        sessionStorage.clear();
        context.clearState();

        if (dataTable === null) {
            getEvents({ variables: { cEMP_Id: idUser } })
        }
        console.log("Cambia", dataTable)
        if(context.state.AuthComponentProps.isAuthenticated===null || context.state.AuthComponentProps.isAuthenticated===false){
            history.push('/secciones');
        }
    }, [dataTable])

    const classes = useStyles();
    return (

        <Page>
            {nameUser}
            <Diaolog
                open={remove}
                handleClose={handleCloseRemove}
                handleClick={deleteEvento}
                title='Eliminar reunión'
                text='Estás seguro de querer eliminar el registro de la reunión?'
                text2='Una vez eliminado el registro no podrás recuperarlo.'
                textButtonA='Cancelar'
                textButtonB='Eliminar' />
            <DialogView open={cerrar} attendees={attendees} title={title} handleClose={()=>setCerrar(false)}/>
            <Homebar navigation={navigation} />
            <PageContainer>
                <Title>Reuniones</Title>
                <Subtitle>Revisa tu calendario, programa o edita tus próximas reuniones.</Subtitle>
            </PageContainer>
            <div style={{ width: "90%", marginTop: 60 }}>
                <Query query={GET_FECHASEVENTS} variables={{ cEMP_Id: idUser }} pollInterval={1000}>
                    {({ loading, error, data }) => {
                        if (loading) return null
                        const aux = data.getCalendarEvents.map(fecha => {
                            const setfecha = {
                                title: fecha.Title,
                                start: new Date(fecha.Start),
                                end: new Date(fecha.End),
                                cREU_Id: fecha.cREU_Id
                            }
                            return setfecha
                        })
                        return (
                            <Calendar
                                localizer={localizer}
                                events={aux}
                                startAccessor="start"
                                endAccessor="end"
                                onSelectEvent={(event => handleEdit(event.cREU_Id))}
                                style={{ height: 500, width: "100%", }}
                                messages={{
                                    next: "sig",
                                    previous: "ant",
                                    today: "Hoy",
                                    month: "Mes",
                                    week: "Semana",
                                    day: "Día"
                                }}
                            />
                        )
                    }}
                </Query>
            </div>
            <div className='div-button-programar'>
                <NavLink exact to="/secciones/reuniones/programar-reunion" className='nav-button-programar'><div className="button-programar">Nueva reunión</div></NavLink>
            </div>
            <PageContainer>
                <Title>Reuniones Agendadas</Title>
                <Table className={classes.table} aria-label="reuniones table">
                    <TableHead className="tableHeader">
                        <TableRow>
                            <StyledTableCell
                                className={`${statusFilter === 'date' ? 'filterActive' : ''}`}
                                onClick={() => actions('filterByDate', 'date')}
                            >
                                <div className="headerTitle">
                                    <p>Fecha</p>
                                    <FilterListIcon />
                                </div>
                            </StyledTableCell>
                            <StyledTableCell
                                className={`${statusFilter === 'hour' ? 'filterActive' : ''}`}
                                onClick={() => actions('filterByHour', 'hour')}
                            >
                                <div className="headerTitle">
                                    <p>Hora</p>
                                    <FilterListIcon />
                                </div>
                            </StyledTableCell>
                            <StyledTableCell
                                className={`${statusFilter === 'title' ? 'filterActive' : ''}`}
                                onClick={() => actions('filterByTitle', 'title')}
                            >

                                <div className="headerTitle">
                                    <p>Reunión</p>
                                    <FilterListIcon />

                                </div>
                            </StyledTableCell>
                            <StyledTableCell
                                className={`table-witdth-movil ${statusFilter === 'place' ? 'filterActive' : ''}`}
                                onClick={() => actions('filterByPlace', 'place')}
                            >
                                <div className="headerTitle">
                                    <p> Lugar</p>
                                    <FilterListIcon />
                                </div>
                            </StyledTableCell>
                            <StyledTableCell
                                className={`${statusFilter === 'idMeet' ? 'filterActive' : ''}`}
                                onClick={() => actions('filterByNumber', 'idMeet')}
                            >
                                <div className="headerTitle">
                                    <p>Id Reunión</p>
                                    <FilterListIcon />
                                </div>
                            </StyledTableCell>
                            <StyledTableCell className='table-witdth-movil'>Editar</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody style={{ maxHeight: "200px" }}>
                        {dataTable === null &&
                            <TableRow>
                                <TableCell colSpan="6" style={{ textAlign: 'center', height: '400px' }}>Cargando</TableCell>
                            </TableRow>
                        }
                        {dataTable && dataTable.map(calendar => (
                            <TableRow key={calendar.cREU_Id}>
                                <TableCell style={{ textAlign: "center" }}>{calendar.Fecha}</TableCell>
                                <TableCell style={{ textAlign: "center" }}>{calendar.Hora}</TableCell>
                                <TableCell style={{ textAlign: "center" }}>{calendar.Title}</TableCell>
                                <TableCell style={{ textAlign: "center" }} className='table-witdth-movil'>{calendar.Lugar}</TableCell>
                                <TableCell style={{ textAlign: "center" }}>{calendar.cREU_Id}</TableCell>
                                <TableCell style={{ display: 'flex', justifyContent: 'center' }} className='table-witdth-movil'>
                                    <div style={{ display: "flex" }}>
                                        <div style={{ fontSize: "25px", marginRight: "5px", marginLeft: "5px" }}>
                                            <DeleteEvent Icon={<IconEdit />} onClick={handleEdit} cREU_Id={calendar.cREU_Id} />
                                        </div>
                                        <div style={{ fontSize: "25px", marginRight: "5px", marginLeft: "5px" }}>
                                            <DeleteEvent Icon={<IconRemove />} onClick={handleOpenRemove} cREU_Id={calendar.cREU_Id} cREU_IdOutlook={calendar.cREU_IdOutlook} />
                                        </div>
                                        <div style={{fontSize:"25px",marginRight:"5px", marginLeft:"5px"}}>
                                            <DeleteEvent Icon={<VisibilityIcon/>}
                                            onClick={handleViewEvent} cREU_IdOutlook={calendar.cREU_IdOutlook} cREU_Id={calendar.Title}/>
                                            
                                        </div>
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </PageContainer>
            <Copyright color="#222" backgroundColor="#fff">
                <img src="/images/logo_patria.png" alt="logo_patria" width={68} />
            </Copyright>
        </Page>
    )
}

export default withRouter(Reuniones);