import React, { Fragment} from "react";
import { NavLink,Redirect } from "react-router-dom"
import Navbar from './Navbar';
import Footer from './Footer';
import Copyright from './Copyright'
import './components.css'
const Home=({session})=>{
    let userAutenticade= (session.obtenerUsuario)?<Redirect to="/secciones"/>:null
    const body = (
        <>
            <Navbar/>
            {userAutenticade}
            <section className="main">
                <div className="main-container">
                    <div className="main-container-col">
                        <h1 className="main-title">Gestión de Clientes para crecer nuestro negocio</h1>
                        <p className="main-subtitle">La plataforma que permite administrar la <br/>información de nuestros clientes en un solo lugar.</p>
                        <NavLink exact to="/login"><div className="button-main">Iniciar sesión</div></NavLink>
                    </div>
                    <div className="main-container-col">
                        <img src='/images/Metric.png' alt="Metric"/>
                    </div>
                </div>
            </section>
            <section className="press">
                <div className="press-container">
                    <img src="/images/SIREC.png" alt="sirec" width={150}/>
                    <img src="/images/SIPAM.png" alt="sipam" width={150}/>
                </div>
            </section>
            <section className="features">
                <div className="features-container">
                    <h2 className="feature-title">Gestión de información para el negocio</h2>
                    <div className="feature-subtitle">Todo lo que necesitas saber de tus clientes para mantener buenas relaciones.</div>
                </div>
                <div className="feature-row">
                    <div className="feature-col-2">
                        <img src="/images/Metric-2.png" alt="Metric-2"/>
                    </div>
                    <div className="feature-col-2">
                        <img src="/images/client.png" alt="client" height={30} className="feature-icon"/>
                        <h3>Snapshot</h3>
                        <p>Resumen de la situación actual del cedente u bróker.<br/> Información de la relación con Patria Re, así como los objetivos de crecimiento.</p>
                    </div>
                </div>
                <div className="feature-row">
                    <div className="feature-col-2">
                        <img src="/images/discussion.png" alt="discussion" height={30} className="feature-icon"/>
                        <h3>Viajes y reuniones</h3>
                        <p>Registro de la información de los encuentros con las diferentes figuras dentro de los procesos de comercialización de Patria Re. Gestión de la información y registro de visitas.</p>
                    </div>
                    <div className="feature-col-2">
                        <img src="/images/phone.png" alt="phone"/>
                    </div>
                </div>
                <div className="small-feature">
                    <div className="small-feature-col-3">
                        <img src="/images/application.png" alt="application" height={30}/>
                        <h3>Dashboard</h3>
                        <p>Principales indicadores de las relaciones administradas para apoyo en la toma de decisiones.</p>
                    </div>
                    <div className="small-feature-col-3">
                        <img src="/images/consulting.png" alt="consulting" height={30}/>
                        <h3>CRM</h3>
                        <p>Gestión de oportunidades, resolución de problemas, mantenimiento de las relaciones con cedentes y brókers.</p>
                    </div>
                    <div className="small-feature-col-3">
                        <img src="/images/team.png" alt="team" height={30}/>
                        <h3>Agenda de visitas</h3>
                        <p>Registro de visitas y resultado de las mismas. Adminitración y visualización de agendas compartidas comparativas dentro de los diferentes equipos.</p>
                    </div>
                    <div className="small-feature-col-3">
                        <img src="/images/businessman.png" alt="businessman" height={30}/>
                        <h3>Viáticos</h3>
                        <p>Adminitración de las solicitudes, autorizaciones y comprobación de los gastos asignados por viajes.</p>
                    </div>
                </div>
            </section>
            <Footer/>
            <Copyright/>
        </>
    );
    return (
        <Fragment>
            {body}
        </Fragment>
    )  
}
export default Home;