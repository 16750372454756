import React, { useState, useEffect,useContext } from 'react';
import Homebar from './Homebar';
import Acordeon from './Acordeon';
import SearchAutoComplete from './SearchAutoComplete';
import SearchInvitadosSirec from './SearchInvitadosSirec';
import ButtonDelete from './ButtonDelete';
import DialogAlert from './DiaologAlert';
import SteperReuniones from './StepperReuniones';
import UserAutoComplete from './UserAutoComplete';
import {Page,PageContainer,Title,Subtitle,AcordeonContainer,AcordeonContainerBody,ContainerTwoInputs,InputTwo,BottonStyledSeccion} from './StylesComponents/common-styled';
import {Typography,TextField,TableContainer,Table,TableBody,TableRow,TableCell,TableHead,Paper,NativeSelect,Checkbox,Icon,TextareaAutosize} from '@material-ui/core';
import {Person,Clear,RadioButtonChecked,RadioButtonUnchecked,CheckCircle,Error,Email,RemoveCircle} from '@material-ui/icons';
import {Calendar} from 'antd';
import {useLazyQuery,useQuery, useMutation}from 'react-apollo';
import {GETCOUNTRIES_SIREC,GETMESSAGE_SIREC,GETCITIES_SIREC,GETEMPLOYES_SIREC,GETRETROCESSIONARY_SIREC,GETCOMPANI_SIREC,GETCORREDOR_SIREC,GETINVITADOS_SIREC,GETCORREDOR_SIREC_ById,GETCOMPANI_SIREC_ById,GETRETROCESSIONARY_SIREC_ById,GET_TEXTMESSAGE_SIREC,GET_CIUDADESBYID} from '../query/query';
import {CREATE_EVENT,CREATE_EVENTCOMPANY,CREATE_EVENTCORREDOR,CREATE_EVENTEJECUTIVO,CREATE_EVENTINVITADOS,CREATE_EVENTRETROCESIONARIO,UPDATE_EVENT,UPDATE_INVITADOS}from '../mutation/mutation';
import 'antd/dist/antd.css';
import './components.css';
import {MyContext} from './Context';
import {withRouter} from 'react-router-dom';
import {useStyles,BootstrapInput,StyledTableCell,tableStyles} from './StylesComponents/material-ui-styled';
import {navigation,tipo_reunion,fechaActual,horaActual,horaMas} from '../funciones-auxiliares/programar-reunion';
import {createEvent,getEvent,updateEvent} from '../services/GraphService';
import CheckIcon from '@material-ui/icons/Check';
const moment=require('moment-timezone');

export const getWidth=()=>window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
const useSizeDimmensions=()=>{
  const [size,setSize]=useState(getWidth());
  useEffect(()=>{
      let timeoutId=null;
      const resizeListener=()=>{
          clearTimeout(timeoutId);
          console.log("width",getWidth())
          timeoutId=setTimeout(()=>{
              setSize(getWidth())
          },100)
      };
      window.addEventListener('resize',resizeListener);
      return()=>window.removeEventListener('resize',resizeListener)
  },[])
  return size;
}

function getSteps() {
    return ['Título de la reunión', 'Detalles de la reunión', 'Lugar de reunión','Agregar invitados','Asistentes a la reunión'];
  }

const ProgramarReunion =({session,history})=>{
    const context=useContext(MyContext);
    let nameUser =(session.obtenerUsuario)?`${session.obtenerUsuario.cEMP_Nombre}`:history.push('/')
    console.log('ses',session.obtenerUsuario)
    let idUser=(session.obtenerUsuario)?session.obtenerUsuario.cEMP_Id:null
    //-----------Queries
    useQuery(GETCOUNTRIES_SIREC,{
        onCompleted({idCatCountries}){
            setDataCountries(idCatCountries);
        }
    });

    useQuery(GETMESSAGE_SIREC,{
        onCompleted({idCatMessageTemplate}){
            setDataMessage(idCatMessageTemplate);
            console.log(idCatMessageTemplate)
        }
    });


    useQuery(GETEMPLOYES_SIREC,{
        onCompleted({idEmployeSirec}){
            setDataEmployes(idEmployeSirec);
        }
    })

    const [getCitiesSirec]=useLazyQuery(GETCITIES_SIREC,{
        onCompleted({idCatCity}){
            setDataCities(idCatCity);
        }
    });

    const [getCities]=useLazyQuery(GET_CIUDADESBYID,{
        onCompleted({getCiudadById}){
            setDataCiudades(getCiudadById);
        }
    })
    
    const [getRetrocessionarySirec]=useLazyQuery(GETRETROCESSIONARY_SIREC,{
        onCompleted({idRetrocessionarySirec}){
            setRetrocessionary(idRetrocessionarySirec)
            console.log(idRetrocessionarySirec)
        }
    })

    const [getRetrocessionarySirec_ById]=useLazyQuery(GETRETROCESSIONARY_SIREC_ById,{
        onCompleted({idRetrocessionarySirecById}){
            console.log(idRetrocessionarySirecById)
            setRetrocessionary([idRetrocessionarySirecById])
        }
    })

    const [getCompanySirec]=useLazyQuery(GETCOMPANI_SIREC,{
        onCompleted({idCompanySirec}){
            setCompany(idCompanySirec)
        }
    })

    const [getCompanySirec_ById]=useLazyQuery(GETCOMPANI_SIREC_ById,{
        onCompleted({idCompanySirecById}){
            console.log(idCompanySirecById)
            setCompany([idCompanySirecById])
        }
    })

    const [getCorredorSirec]=useLazyQuery(GETCORREDOR_SIREC,{
        onCompleted({idCorredorSirec}){
            setCorredor(idCorredorSirec)
        }
    })

    const [getCorredorSirec_ById]=useLazyQuery(GETCORREDOR_SIREC_ById,{
        onCompleted({idCorredorSirecById}){
            console.log(idCorredorSirecById)
            setCorredor([idCorredorSirecById])
        }
    })

    const [getCargosRetrosecionary]=useLazyQuery(GETINVITADOS_SIREC,{
        onCompleted({idInvitadosSirec}){
            const cargos=idInvitadosSirec.map((invitado)=>invitado?{...invitado,status:false}:null)
            setCargosSirec([...CargosSirec,...cargos])
            sessionStorage.setItem("rEjexORG",JSON.stringify([...CargosSirec,...cargos]))
        }
    })

    const [getTextMessage]=useLazyQuery(GET_TEXTMESSAGE_SIREC,{
        onCompleted({getTextMessageById}){
            setTextMessage(getTextMessageById.cPLA_textPlantilla)
        }
    })

    //------------Mutations
    const [crearEvento]=useMutation(CREATE_EVENT,{
        onCompleted({createEvents}){
            sessionStorage.setItem('cREU_Id',createEvents.cREU_Id)
        }
    })

    const [crearEventoCompany]=useMutation(CREATE_EVENTCOMPANY,{
        onCompleted({createEventsCompany}){
            console.log(createEventsCompany)
        }
    })

    const [crearEventoCorredor]=useMutation(CREATE_EVENTCORREDOR,{
        onCompleted({createEventsCorredor}){
            console.log(createEventsCorredor)
        }
    })

    const [crearEventoEjecutivo]=useMutation(CREATE_EVENTEJECUTIVO,{
        onCompleted({createEventsEjecutivo}){
            console.log(createEventsEjecutivo)
        }
    })

    const [crearEventoInvitados]=useMutation(CREATE_EVENTINVITADOS,{
        onCompleted({createEventsInvitados}){
            console.log("Eventos",createEventsInvitados)
        }
    })

    const [crearEventoRetrocesionario]=useMutation(CREATE_EVENTRETROCESIONARIO,{
        onCompleted({createEventsRetrocesionario}){
            console.log(createEventsRetrocesionario)
        }
    })

    const [updateInvitados]=useMutation(UPDATE_INVITADOS,{
        onCompleted({updateEventsInvitados}){
            console.log(updateEventsInvitados)
        }
    })

    const [editarEvento]=useMutation(UPDATE_EVENT,{
        onCompleted({updateEvents}){
            console.log(updateEvents);
        }
    })
    
    //States
    const [FechaInicio,setFechaInicio]=useState(context.state.cREU_FechaInicio===""?fechaActual():context.state.cREU_FechaInicio);
    const [HoraInicio,setHoraInicio]=useState(context.state.cREU_HoraInicio===""?horaActual():context.state.cREU_HoraInicio);
    const [FechaFinal,setFechaFinal]=useState(context.state.cREU_FechaFin===""?fechaActual():context.state.cREU_FechaFin);
    const [HoraFinal,setHoraFinal]=useState(context.state.cREU_HoraFin===""?horaMas(horaActual()):context.state.cREU_HoraFin);
    const [HoraZona,setHoraZona]=useState('');
    const [Subject,setSubject]=useState(context.state.cREU_Titulo);
    const [IdContries,setIdContries]=useState(0);
    const [textMessage,setTextMessage]=useState('');
    const [DataCountries,setDataCountries]=useState('');
    const [DataMessage,setDataMessage]=useState('');
    const [DataCities,setDataCities]=useState('');
    const [DataEmployes,setDataEmployes]=useState('');
    const [Invitados,setInvitados]=useState(context.state.invitadosReunion);
    const [Retrocessionary,setRetrocessionary]=useState([]);
    const [Company,setCompany]=useState([]);
    const [Corredor,setCorredor]=useState([]);
    const [CargosSirec,setCargosSirec]=useState(context.state.rEjexORG);
    const [cCIA_Id,setcCIA_Id]=useState([]);
    const [cCIA_Version,setcCIA_Version]=useState([]);
    const [cCOR_Id,setcCOR_Id]=useState([]);
    const [cCOR_Version,setcCOR_Version]=useState([]);
    const [cRET_Id,setcRET_ID]=useState([]);
    const [cRET_Version,setcRET_Version]=useState([]);
    const [linkConferencia,setlinkConferencia]=useState(context.state.cREU_ConferenciaURL);
    const [dataCuidades,setDataCiudades]=useState([]);
    const [openDialog,setOpenDialog]=useState(false);
    const [disabledCompany,setDisabledCompany]=useState(false);
    const [disabledCorredor,setDisabledCorredor]=useState(false);
    const [secAcordeon,setSecAcordeon]=useState({
                                                    secTitulo:false,
                                                    secDetalles:false,
                                                    secLugar:false,
                                                    secInvitados:false,
                                                    secAsistentes:false,
                                                });
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState({});
    const [cREU_Lugar,setcREU_Lugar]=useState(context.state.cREU_Lugar || "");
    const [aux_cEDO_Id,setaux_cEDO_Id]=useState(context.state.cEDO_Id || "0");
    const [aux_cPAI_Id,setaux_cPAI_Id]=useState(context.state.cPAI_Id || "0");
    const [aux_claveCorredor,setaux_claveCorredor]=useState("");
    const [aux_claveCompany,setaux_claveCompany]=useState("");
    const [aux_cREU_TipoReunion, setaux_cREU_TipoReunion]=useState(context.state.cREU_TipoReunion || "")
    const [aux_cPLA_Id, setaux_cPLA_Id]=useState(context.state.cPLA_Id || "")
    const [attendees,setAttendees]=useState([]);
    
    
    const HandleExpand=(seccion)=>{
        const aux=secAcordeon[seccion];
        setSecAcordeon({...secAcordeon,[seccion]:!aux})
    }

    const HandleExpandStepper=(step)=>{
        const aux={
            secTitulo:false,
            secDetalles:false,
            secLugar:false,
            secInvitados:false,
            secAsistentes:false,
        }
        switch(step){
            case 0:setSecAcordeon({...aux,secTitulo:true})
                   window.scrollTo(0, 0)
                   break;
            case 1:setSecAcordeon({...aux,secDetalles:true})
                   document.getElementById('detalles-reunion').scrollIntoView()
                   break;
            case 2:setSecAcordeon({...aux,secLugar:true})
                   document.getElementById('lugar-reunion').scrollIntoView()
                   break;
            case 3:setSecAcordeon({...aux,secInvitados:true})
                   document.getElementById('invitados-reunion').scrollIntoView()
                   break;
            case 4:setSecAcordeon({...aux,secAsistentes:true})
                   document.getElementById('asistentes-reunion').scrollIntoView()
                   break;
            default:break;
        }
    }

    const handleComplete = () => {
        if(sessionStorage.getItem('cREU_Titulo')!==null && sessionStorage.getItem('cREU_FechaActualPais')){
            const newCompleted = completed;
            newCompleted[0] = true;
            setCompleted(newCompleted);
            handleNext();
        }
        if(sessionStorage.getItem('cEMP')!==null || sessionStorage.getItem('cEMP_Edit')!==null){
            const newCompleted = completed;
            newCompleted[1] = true;
            setCompleted(newCompleted);
            handleNext();
        }
        if(sessionStorage.getItem('cREU_Lugar')!==null && sessionStorage.getItem('cPAI_Id')!==null && sessionStorage.getItem('cEDO_Id')!==null && sessionStorage.getItem('cREU_FechaActualPais')!==null){
            const newCompleted = completed;
            newCompleted[2] = true;
            setCompleted(newCompleted);
            handleNext();
        }
        if(sessionStorage.getItem('cRET_Id')!=null || sessionStorage.getItem('cCOR_Id')!==null || sessionStorage.getItem('cCIA_Id')!=null) {
            const newCompleted = completed;
            newCompleted[3] = true;
            setCompleted(newCompleted);
            handleNext();
        }
        if(sessionStorage.getItem('cREU_TipoReunion')!==null && sessionStorage.getItem('cPLA_Id')!==null){
            const newCompleted = completed;
            newCompleted[4] = true;
            setCompleted(newCompleted);
            handleNext();
        }
        
      };

    const handleCloseDialog=()=>{
        setOpenDialog(false)
        history.push('/secciones/reuniones')
        //window.location.href='/secciones/reuniones'
    };

    const totalSteps = () => {
        return getSteps.length;
      };
    
      const completedSteps = () => {
        return Object.keys(completed).length;
      };
    
      const isLastStep = () => {
        return activeStep === totalSteps() - 1;
      };
    
      const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
      };
    
      const getPosAttendees=(mail,attendees)=>{
          let aux=-1;
          let pos=0;
          attendees.forEach(attende=>{
            if(attende.address==mail){
                aux=pos;
                console.log('POsss=>>>',pos)
            }
            pos++;
          })
          return aux;
      }

      const handleNext = () => {
        const newActiveStep =
          isLastStep() && !allStepsCompleted()
            ? 
              getSteps.findIndex((step, i) => !(i in completed))
            : activeStep + 1;
        setActiveStep(newActiveStep);
      };
    
    
      const handleStep = (step) => () => {
        setActiveStep(step);
        HandleExpandStepper(step)
      };

    const guardarInvitados=(id)=>{
        crearEventoInvitados({variables:{
            cREU_Id:parseInt(sessionStorage.getItem('cREU_Id'),10),
            cEMP_Id:id
        }})
    }


    const pushInvitados=(invitado)=>{
        setInvitados([...Invitados,invitado])
        sessionStorage.setItem("cEMP",JSON.stringify([...Invitados,invitado]))
        handleComplete()
    }
    const deleteInvitados=(invitado)=>{
        const edit=searchEditInvitados(invitado)
        if(edit===undefined){
            const aux=Invitados.filter((item)=>item.name!==invitado);
            sessionStorage.setItem("cEMP",JSON.stringify(aux))
            setInvitados(aux);
        }
        else{
            sessionStorage.setItem("cEMP_Edit",JSON.stringify(Invitados.map((item)=>item.name===invitado && item.status_db?{...item,status_db:!item.status_db}:item)))
            const aux=Invitados.filter((item)=>item.name!==invitado);
            setInvitados(aux);
        }
        
    }

    const searchEditInvitados=(invitado)=>{
        const aux=Invitados.find(Invitado=>Invitado.name==invitado && Invitado.status_db)
        return aux
    }
    const editInvitados=(indexEdit,statusEdit)=>{
        setInvitados(Invitados.map((invitado,index)=>index===indexEdit?{...invitado,status:statusEdit}:invitado));
        sessionStorage.setItem("cEMP",JSON.stringify(Invitados.map((invitado,index)=>index===indexEdit?{...invitado,status:statusEdit}:invitado)))
    }

    const editEje=(indexEdit,statusEdit)=>{
        setCargosSirec(CargosSirec.map((cargo,index)=>index===indexEdit?{...cargo,status:statusEdit}:cargo))
        sessionStorage.setItem("rEjexORG",JSON.stringify(CargosSirec.map((cargo,index)=>index===indexEdit?{...cargo,status:statusEdit}:cargo)))
    }
    const getInvitadosSirec=async(ID,Tipo,version)=>{
        if(Tipo===1){
            await getCargosRetrosecionary({variables:{cTORG_Id:2,cORG_Id:ID}})
            await getCargosRetrosecionary({variables:{cTORG_Id:3,cORG_Id:ID}})
            if(version.cRET_Version===undefined){
                sessionStorage.setItem('cCOR_Id',JSON.stringify([...cCOR_Id,ID]));
                setcCOR_Id([...cCOR_Id,ID])
                sessionStorage.setItem('cCOR_Version',JSON.stringify([...cCOR_Version,version.cCOR_Version]));
                setcCOR_Version([...cCOR_Version,version.cCOR_Version])
            }
            else{
                sessionStorage.setItem('cRET_Id',JSON.stringify([...cRET_Id,ID]));
                setcRET_ID([...cRET_Id,ID])
                sessionStorage.setItem('cRET_Version',JSON.stringify([...cRET_Version,version.cRET_Version]));
                setcRET_Version([...cRET_Version,version.cRET_Version])
            }
            setDisabledCompany(true)
        }else{
            await getCargosRetrosecionary({variables:{cTORG_Id:1,cORG_Id:ID}})
            sessionStorage.setItem('cCIA_Id',JSON.stringify([...cCIA_Id,ID]))
            setcCIA_Id([...cCIA_Id,ID])
            sessionStorage.setItem('cCIA_Version',JSON.stringify([...cCIA_Version,version.cCIA_Version]))
            setcCIA_Version([...cCIA_Version,version.cCIA_Version])
            setDisabledCorredor(true)
        }
        handleComplete()
    }
    const onChange=(e)=>{
        switch(e.target.id){
            case 'date-inicio':
                console.log(e.target.value);
                setFechaInicio(e.target.value);
                setFechaFinal(e.target.value);
                sessionStorage.setItem('cREU_FechaInicio',e.target.value)
                sessionStorage.setItem('cREU_FechaFin',e.target.value);
                getHourforZona()
                break;
            case 'date-final':
                setFechaFinal(e.target.value);
                console.log(FechaFinal);
                sessionStorage.setItem('cREU_FechaFin',e.target.value)
                break;
            case 'hora-inicio':
                setHoraInicio(e.target.value);
                setHoraFinal(horaMas(e.target.value));
                sessionStorage.setItem('cREU_HoraInicio',e.target.value)
                sessionStorage.setItem('cREU_HoraFin',horaMas(e.target.value))
                getHourforZona();
                break;
            case 'hora-final':
                setHoraFinal(e.target.value);
                sessionStorage.setItem('cREU_HoraFin',e.target.value)
                break;
            case 'name-reunion':
                setSubject(e.target.value);
                sessionStorage.setItem('cREU_Titulo',e.target.value)
                handleComplete()
                break;
            case 'ciudad-reunion':
                setIdContries(e.target.value);
                console.log("Countries",IdContries);
                sessionStorage.setItem('cEDO_Id',e.target.value)
                setaux_cEDO_Id(e.target.value)
                handleComplete()
                break;
            case 'zona-horaria':
                sessionStorage.setItem('cREU_ZonaHoraria',e.target.value)
                getHourforZona();
                break;
            case 'pais-reunion':
                sessionStorage.setItem('cPAI_Id',e.target.value)
                getCitiesSirec({variables:{cPAI_Id:parseInt(e.target.value,10)}});
                getCities({variables:{cPAI_Id:parseInt(e.target.value,10)}});
                getRetrocessionarySirec({variables:{cPAI_Id:parseInt(e.target.value,10)}});
                getCompanySirec({variables:{cPAI_Id:parseInt(e.target.value,10)}});
                getCorredorSirec({variables:{cPAI_Id:parseInt(e.target.value,10)}});
                setaux_cPAI_Id(e.target.value)
                handleComplete()
                break;
            case 'lugar-reunion':
                    sessionStorage.setItem('cREU_Lugar',e.target.value)
                    setcREU_Lugar(e.target.value)
                    handleComplete()
                    break;
            case 'link-conferencia':
                    setlinkConferencia(e.target.value)
                    sessionStorage.setItem('cREU_ConferenciaURL',e.target.value)
                    break;
            case 'tipo-reunion':
                console.log(e.target.value);
                sessionStorage.setItem('cREU_TipoReunion',e.target.value)
                setaux_cREU_TipoReunion(e.target.value)
                handleComplete()
                break;
            case 'plantilla-mensaje':
                getTextMessage({variables:{cPLA_Id:parseInt(e.target.value,10)}})
                sessionStorage.setItem('cPLA_Id',e.target.value)
                setaux_cPLA_Id(e.target.value)
                handleComplete()
                break;
            case 'clave-reunion-corredor':
                getRetrocessionarySirec_ById({variables:{cRET_Id:parseInt(e.target.value,10)}})
                getCorredorSirec_ById({variables:{cCOR_Id:parseInt(e.target.value,10)}})
                setaux_claveCorredor(parseInt(e.target.value,10))
                break;
            case 'text-mensaje':
                setTextMessage(e.target.value)
                sessionStorage.setItem('textMessage',e.target.value)
            default:break;
        }
    }
    const onChangeCompany=({target})=>{
        getCompanySirec_ById({variables:{cCIA_Id:parseInt(target.value,10)}})
        setaux_claveCompany(parseInt(target.value,10))
        handleComplete()
    }
    const onChangeCalendar=(value)=>{
        setFechaInicio(value._d.toISOString().slice(0,10));
        setFechaFinal(value._d.toISOString().slice(0,10));
        sessionStorage.setItem('cREU_FechaInicio',value._d.toISOString().slice(0,10))
        sessionStorage.setItem('cREU_FechaFin',value._d.toISOString().slice(0,10));
        getHourforZona()
        console.log("valor",value._d.toISOString().slice(0,10))
        handleComplete()
    }
    const getHourforZona=()=>{
        const m=moment.tz(`${sessionStorage.getItem('cREU_FechaInicio')} ${sessionStorage.getItem('cREU_HoraInicio')}`,'America/Mexico_City');
        sessionStorage.setItem("cREU_FechaActualPais",m.tz(sessionStorage.getItem('cREU_ZonaHoraria')).format().slice(0,10))
        sessionStorage.setItem("cREU_HoraActualPais",m.tz(sessionStorage.getItem('cREU_ZonaHoraria')).format().slice(11,16))
        setHoraZona(sessionStorage.getItem("cREU_HoraActualPais"));
        handleComplete()
    }
    const editUser=(cEMP_Id)=>{
        sessionStorage.setItem('cEMP_Id',cEMP_Id);
    }
    const crearEventOutlook=async()=>{
        const inicio=new Date(`${sessionStorage.getItem('cREU_FechaInicio')} ${sessionStorage.getItem('cREU_HoraInicio')}`);
        const fin=new Date(`${sessionStorage.getItem('cREU_FechaFin')} ${sessionStorage.getItem('cREU_HoraFin')}`);
        const userEvent=JSON.parse(sessionStorage.getItem('userEvent'));
        const saveInvitados=JSON.parse(sessionStorage.getItem("cEMP"));
        const editInvitados=JSON.parse(sessionStorage.getItem('cEMP_Edit'));
        const event = {
            evento:{
                subject: sessionStorage.getItem('cREU_Titulo'),
                body: {
                  contentType: "text",
                  content: sessionStorage.getItem('textMessage')
                },
                start: {
                    dateTime: inicio,
                    timeZone: sessionStorage.getItem('cREU_ZonaHoraria')
                },
                end: {
                    dateTime: fin,
                    timeZone: sessionStorage.getItem('cREU_ZonaHoraria')
                },
                location:{
                    displayName:sessionStorage.getItem('cREU_Lugar')
                },
                attendees: [
                //   {
                //     emailAddress: {
                //       address:context.state.AuthComponentProps.user.email,
                //       name: context.state.AuthComponentProps.user.displayName
                //     },
                //   },
                ],
            }
          };
          if(saveInvitados){
            saveInvitados.forEach((data)=>{
                const aux={
                    emailAddress:{
                        address:data.correo,
                        name:data.name,
                    },
                    type:"required"
                }
                event.evento.attendees.push(aux);
              })
          }
          if(editInvitados){
              console.log("Editar ")
              console.log(editInvitados);
              editInvitados.forEach((data)=>{
                  if(data.status_db){
                      const aux={
                          emailAddress:{
                              address:data.correo,
                              name:data.name,
                          },
                          type:"required"
                      }
                      event.evento.attendees.push(aux);
                  }
              })
              await updateEvent(context.state.AuthComponentProps.token,event.evento,sessionStorage.getItem('cREU_IdOutlook'))
              .then((data)=>console.log(data))
              .catch((err)=>console.log(err));
            //   await OUTLOOK_SERVICE.updateEvent(event,sessionStorage.getItem('cREU_IdOutlook'))
            //   .then((data)=>console.log(data))
            //   .catch((err)=>console.log(err));
           }
          else{
              console.log(event)
              await createEvent(context.state.AuthComponentProps.token,event.evento)
              .then((data)=>{
                  sessionStorage.setItem('cREU_IdOutlook',data.id);
              })
              .catch((err)=>console.log(err))
          }
    }

    const guardarData=async()=>{
            const cREU_Id=sessionStorage.getItem('cREU_Id');
            const cEMP_Id=sessionStorage.getItem('cEMP_Id');
            const cEDO_Id=sessionStorage.getItem('cEDO_Id');
            const cPAI_Id=sessionStorage.getItem('cPAI_Id');
            const cPLA_Id=sessionStorage.getItem('cPLA_Id');
            const cREU_ConferenciaURL=sessionStorage.getItem('cREU_ConferenciaURL');
            const cREU_FechaInicio=sessionStorage.getItem('cREU_FechaInicio');
            const cREU_HoraInicio=sessionStorage.getItem('cREU_HoraInicio');
            const cREU_FechaFin=sessionStorage.getItem('cREU_FechaFin');
            const cREU_HoraFin=sessionStorage.getItem('cREU_HoraFin');
            const cREU_Lugar=sessionStorage.getItem('cREU_Lugar');
            const cREU_Titulo=sessionStorage.getItem('cREU_Titulo');
            const cREU_ZonaHoraria=sessionStorage.getItem('cREU_ZonaHoraria');
            const cREU_FechaActualPais=sessionStorage.getItem('cREU_FechaActualPais');
            const cREU_HoraActualPais=sessionStorage.getItem('cREU_HoraActualPais');
            const cREU_TipoReunion=sessionStorage.getItem('cREU_TipoReunion');
            const saveInvitados=JSON.parse(sessionStorage.getItem("cEMP"));
            
        if(context.state.cREU_Id===null){
            //Guardar
            const savecCIA_Id=JSON.parse(sessionStorage.getItem('cCIA_Id'));
            const savecCIA_Version=JSON.parse(sessionStorage.getItem('cCIA_Version'));
            const savecCOR_Id=JSON.parse(sessionStorage.getItem('cCOR_Id'));
            const savecCOR_Version=JSON.parse(sessionStorage.getItem('cCOR_Version'));
            const savecRET_Id=JSON.parse(sessionStorage.getItem('cRET_Id'));
            const savecRET_Version=JSON.parse(sessionStorage.getItem('cRET_Version'));
            const saverEjexORG=JSON.parse(sessionStorage.getItem('rEjexORG'));


            if(cEMP_Id===null || cEDO_Id===null || cPAI_Id===null || cPLA_Id===null || cREU_FechaInicio===null || cREU_HoraInicio===null || cREU_FechaFin===null || cREU_HoraFin===null || cREU_Lugar===null || cREU_Titulo===null || cREU_ZonaHoraria==null || cREU_FechaActualPais===null || cREU_HoraActualPais===null ||cREU_TipoReunion===null){
                alert("Faltan campos por llenar")
            }
            else{
                await crearEventOutlook();
                await crearEvento({variables:{
                    cEMP_Id:parseInt(cEMP_Id,10),
                    cEDO_Id:parseInt(cEDO_Id,10),
                    cPAI_Id:parseInt(cPAI_Id,10),
                    cPLA_Id:parseInt(cPLA_Id,10),
                    cREU_ConferenciaURL:cREU_ConferenciaURL,
                    cREU_FechaInicio:cREU_FechaInicio,
                    cREU_HoraInicio:cREU_HoraInicio,
                    cREU_FechaFin:cREU_FechaFin,
                    cREU_HoraFin:cREU_HoraFin,
                    cREU_Lugar:cREU_Lugar,
                    cREU_Titulo:cREU_Titulo,
                    cREU_ZonaHoraria:cREU_ZonaHoraria,
                    cREU_FechaActualPais:cREU_FechaActualPais,
                    cREU_HoraActualPais:cREU_HoraActualPais,
                    cREU_TipoReunion:cREU_TipoReunion,
                    cREU_IdOutlook:sessionStorage.getItem('cREU_IdOutlook'),
                    cCIUD_Id:2
                }})
                if(saveInvitados!==null){
                    saveInvitados.forEach(async(invitados)=>{
                        await guardarInvitados(parseInt(invitados.cEMP_Id),10)
                    })
                }
                if(savecCOR_Id!==null && savecCOR_Version!==null){
                    savecCOR_Id.forEach(async(corredor,index)=>{
                        await crearEventoCorredor({variables:{
                            cREU_Id:parseInt(sessionStorage.getItem('cREU_Id'),10),
                            cCOR_Id:savecCOR_Id[index],
                            cCOR_Version:savecCOR_Version[index]
                    }})
                })
                }
                if(savecCIA_Id!==null && savecCIA_Version!==null){
                    savecCIA_Id.forEach(async(company,index)=>{
                        await crearEventoCompany({variables:{
                            cREU_Id:parseInt(sessionStorage.getItem('cREU_Id'),10),
                            cCIA_Id:savecCIA_Id[index],
                            cCIA_Version:savecCIA_Version[index]
                        }})
                    })
                }
                if(savecRET_Id!==null && savecRET_Version!==null){
                    savecRET_Id.forEach(async(retro,index)=>{
                        await crearEventoRetrocesionario({variables:{
                            cREU_Id:parseInt(sessionStorage.getItem('cREU_Id'),10),
                            cRET_Id:savecRET_Id[index],
                            cRET_Version:savecRET_Version[index]
                        }})
                    })
                }
                if(saverEjexORG!==null){
                    saverEjexORG.forEach(async(ejecutivo)=>{
                        if(ejecutivo.status){
                            await crearEventoEjecutivo({variables:{
                                cREU_Id:parseInt(sessionStorage.getItem('cREU_Id')),
                                cTORG_Id:ejecutivo.cTORG_Id,
                                cORG_Id:ejecutivo.cORG_Id,
                                cORG_Ver:ejecutivo.cORG_Ver,
                                cEje_Id:ejecutivo.cEje_Id
                            }})
                        }
                    })
                }
                context.clearState()
                sessionStorage.clear();
                //shistory.push('/secciones/reuniones')
                setOpenDialog(true); 
            }
        }
        else{
            //Editar
            const editInvitado=JSON.parse(sessionStorage.getItem("cEMP_Edit"));
            await crearEventOutlook();
            await editarEvento({variables:{
                cREU_Id:parseInt(cREU_Id,10),
                cEMP_Id:parseInt(cEMP_Id,10),
                cEDO_Id:parseInt(cEDO_Id,10),
                cPAI_Id:parseInt(cPAI_Id,10),
                cPLA_Id:parseInt(cPLA_Id,10),
                cREU_ConferenciaURL:cREU_ConferenciaURL,
                cREU_FechaInicio:cREU_FechaInicio,
                cREU_HoraInicio:cREU_HoraInicio,
                cREU_FechaFin:cREU_FechaFin,
                cREU_HoraFin:cREU_HoraFin,
                cREU_Lugar:cREU_Lugar,
                cREU_Titulo:cREU_Titulo,
                cREU_ZonaHoraria:cREU_ZonaHoraria,
                cREU_FechaActualPais:cREU_FechaActualPais,
                cREU_HoraActualPais:cREU_HoraActualPais,
                cREU_TipoReunion:cREU_TipoReunion}})
            if(editInvitado!==null){
                editInvitado.forEach(async(invitado)=>{
                    if(!invitado.status_db){
                        await updateInvitados({variables:{
                            cREU_Id:parseInt(cREU_Id,10),
                            cEMP_Id:parseInt(invitado.cEMP_Id),
                            rREUXEMP_Status:'0'
                        }})
                    }
                })
            }
            if(saveInvitados!==null){
                saveInvitados.forEach(async(invitados)=>{
                    await guardarInvitados(parseInt(invitados.cEMP_Id),10)
                })
            }

            setOpenDialog(true);
            context.clearState()
            sessionStorage.clear();

            
             
        }
         
    }

    const handleReset = () => {
        setActiveStep(0);
        setCompleted({});
      };

    const descartarData=()=>{
        sessionStorage.clear();
        history.push('/secciones/reuniones')
        context.clearState()
    }

    const borrarData=()=>{
        setSubject('');
        setHoraInicio(horaActual());
        setFechaInicio(fechaActual());
        setHoraFinal(horaActual());
        setFechaFinal(fechaActual());
        setInvitados([]);
        setDataCities([]);
        setlinkConferencia('');
        setCargosSirec([]);
        setDisabledCorredor(false);
        setDisabledCompany(false);
        sessionStorage.clear();
        context.clearState()
        handleReset()
    }

    useEffect(()=>{
        if(context.state.cREU_Id===null){
            sessionStorage.setItem('cREU_FechaInicio',fechaActual());
            sessionStorage.setItem('cREU_HoraInicio',horaActual());
            sessionStorage.setItem('cREU_FechaFin',fechaActual());
            sessionStorage.setItem('cREU_HoraFin',horaMas(horaActual()));
            sessionStorage.setItem('cREU_ZonaHoraria','America/Mexico_city')
            sessionStorage.setItem("cEMP_Id",idUser)
        }
        else{
            sessionStorage.setItem('cEDO_Id',context.state.cEDO_Id)
            sessionStorage.setItem('cEMP_Id',context.state.cEMP_Id)
            sessionStorage.setItem('cPAI_Id',context.state.cPAI_Id)
            sessionStorage.setItem('cPLA_Id',context.state.cPLA_Id)
            sessionStorage.setItem('cREU_Id',context.state.cREU_Id)
            sessionStorage.setItem('cREU_ConferenciaURL',context.state.cREU_ConferenciaURL)
            sessionStorage.setItem('cREU_FechaActualPais',context.state.cREU_FechaActualPais)
            sessionStorage.setItem('cREU_FechaFin',context.state.cREU_FechaFin)
            sessionStorage.setItem('cREU_FechaInicio',context.state.cREU_FechaInicio)
            sessionStorage.setItem('cREU_HoraActualPais',context.state.cREU_HoraActualPais)
            sessionStorage.setItem('cREU_HoraFin',context.state.cREU_HoraFin)
            sessionStorage.setItem('cREU_HoraInicio',context.state.cREU_HoraInicio)
            sessionStorage.setItem('cREU_Lugar',context.state.cREU_Lugar)
            sessionStorage.setItem('cREU_TipoReunion',context.state.cREU_TipoReunion)
            sessionStorage.setItem('cREU_Titulo',context.state.cREU_Titulo)
            sessionStorage.setItem('cREU_ZonaHoraria',context.state.cREU_ZonaHoraria)
            sessionStorage.setItem('cREU_IdOutlook',context.state.cREU_IdOutlook)
            sessionStorage.setItem('cEMP_Edit',JSON.stringify(context.state.invitadosReunion))
            getCitiesSirec({variables:{cPAI_Id:context.state.cPAI_Id}});
            getRetrocessionarySirec({variables:{cPAI_Id:context.state.cPAI_Id}});
            getCompanySirec({variables:{cPAI_Id:context.state.cPAI_Id}});
            getCorredorSirec({variables:{cPAI_Id:context.state.cPAI_Id}});
            getTextMessage({variables:{cPLA_Id:context.state.cPLA_Id}})
            getHourforZona()
            console.log('===>Invitados',Invitados)
            getEvent(context.state.AuthComponentProps.token,context.state.cREU_IdOutlook)
                .then(({attendees})=>{
                    console.log("Attendess===>",attendees)
                    let aux=context.state.invitadosReunion
                    for(let i=0;i<attendees.length;i++){
                        for(let j=0;j<aux.length;j++){
                            if(aux[j].correo==attendees[i].emailAddress.address){
                                if(attendees[i].status.response=='none'){
                                    aux[j]={...aux[j],
                                        statusOutlook:0
                                        }
                                }
                                else if(attendees[i].status.response=='tentativelyAccepted'){
                                    aux[j]={...aux[j],
                                        statusOutlook:2
                                        }
                                }
                                else if(attendees[i].status.response=='accepted'){
                                    aux[j]={...aux[j],
                                        statusOutlook:3
                                        }
                                }
                                else{
                                    aux[j]={...aux[j],
                                        statusOutlook:1
                                        }
                                }

                                
                            }
                        }
                    }
                    console.log("Sttatus====>",aux)
                    setInvitados(aux);
                })
                .catch((err)=>console.log('Error evento----->',err));
        }
        window.scrollTo(0, 0)
        handleComplete()
    },[context,getCitiesSirec,getCompanySirec,getCorredorSirec,getRetrocessionarySirec,getTextMessage,idUser])

    useEffect(()=>{
        if(context.state.AuthComponentProps.isAuthenticated===null || context.state.AuthComponentProps.isAuthenticated===false){
            history.push('/secciones');
        }
    },[])
    const classes =useStyles();
    const tableClasses=tableStyles();
    return(
        <Page id="ProgramarReunion">
            {nameUser}
            <DialogAlert 
            title=''
            text='Exito al guardar reunión'
            textButtonA='Aceptar'
            open={openDialog}
            handleClose={handleCloseDialog}/>
            <Homebar navigation={navigation}/>
            <PageContainer position='fixed' marginTop='88px' marginBottom="388px">
                <Title>Configuración de la reunión</Title>
                <Subtitle>Personaliza tu reunión y los detalles de los asistentes</Subtitle>
                <SteperReuniones activeStep={activeStep} completed={completed} handleComplete={handleComplete} getSteps={getSteps} handleStep={handleStep}/>
            </PageContainer>
            <div className='seccion-reuniones'>
                <Typography>Título de la reunión</Typography>
                <TextField id="name-reunion" fullWidth placeholder='Ingresa nombre' value={Subject}  variant="outlined" onChange={onChange} className={`${Subject!=""?'inputActive':''}`}/>
            </div>
            <AcordeonContainer>
                <Acordeon imgSrc='/images/Icons/calendario.png' label="Selección de fecha y hora de la reunión" margin={'0px 10px 0px 0px'} expanded={secAcordeon.secTitulo} setExpanded={HandleExpand}seccion='secTitulo'>
                        <div className="calendar">
                            <Calendar fullscreen={false} onSelect={onChangeCalendar} value={moment(FechaInicio)}/>
                        </div>
                        <AcordeonContainerBody>
                            <ContainerTwoInputs>
                                <InputTwo id='date-inicio' label='Fecha inicio' type='date' variant='outlined'  value={FechaInicio} onChange={onChange}/>
                                <InputTwo id='hora-inicio' label='Hora inicio' type='time' variant='outlined' value={HoraInicio} onChange={onChange}/>
                            </ContainerTwoInputs>
                            <Typography style={{textAlign:"center"}}>-</Typography>
                            <ContainerTwoInputs>
                                <InputTwo id='date-final' label='Fecha final' type='date' variant='outlined' value={FechaFinal} onChange={onChange}/>
                                <InputTwo id='hora-final' label='Hora final' type='time' variant='outlined' value={HoraFinal} onChange={onChange}/>
                            </ContainerTwoInputs>
                        </AcordeonContainerBody>
                </Acordeon>
            </AcordeonContainer>
            <AcordeonContainer id='detalles-reunion'>
                <Acordeon imgSrc='/images/Icons/Detalles.png' label="Detalles de la reunión" margin={'0px 10px 0px 0px'} expanded={secAcordeon.secDetalles} setExpanded={HandleExpand}seccion='secDetalles'>
                    <AcordeonContainerBody flexDirection="column" marginBottom="20px">
                        <Typography>Persona quien realiza reunión</Typography>
                        {/* <TextField id="user-name" variant="outlined" defaultValue={nameUser} style={{width:"100%"}} disabled/> */}
                        <UserAutoComplete User={nameUser} Employes={DataEmployes} editUser={editUser}/>
                    </AcordeonContainerBody>
                    <AcordeonContainerBody flexDirection="column" marginBottom="20px">
                        <Typography>Invitados equipo patria</Typography>
                        {DataEmployes&&
                            <SearchAutoComplete Employes={DataEmployes} getInvitado={pushInvitados}/>
                        }
                    </AcordeonContainerBody>
                    <TableContainer component={Paper} style={{width:"90%"}}>
                            {Invitados&&(
                            <Table>
                                <TableBody>
                                        {Invitados.map((invitado,index)=>(
                                            <TableRow key={index}>
                                                <TableCell className="table-invitados-r"><Person/></TableCell>
                                                <TableCell className="table-invitados-r">{invitado.name}</TableCell>
                                                <TableCell className="table-invitados-r">{invitado.correo}</TableCell>
                                                <TableCell className="table-invitados"><ButtonDelete Icon={<Clear/>} name={invitado.name} deleteFunction={deleteInvitados}/></TableCell>
                                                <TableCell className="table-invitados-r"><Checkbox icon={<RadioButtonUnchecked/>} checkedIcon={<RadioButtonChecked/>} color="default"  onChange={(e)=>editInvitados(index,e.target.checked)} checked={invitado.status}/></TableCell>
                                                <TableCell className="table-invitados">Enviar notificación</TableCell>
                                                <TableCell className="table-invitados-r">{invitado.status?<CheckCircle color="primary" />:<Error/>}</TableCell>
                                                {context.state.cREU_Id!==null?
                                                    <>
                                                        <TableCell className="table-invitados">
                                                            Status
                                                        </TableCell>
                                                        <TableCell className="table-invitados-r">
                                                            {invitado.statusOutlook===3?<CheckCircle style={{ color: 'green' }}/>:invitado.statusOutlook===2?<CheckCircle style={{ color: 'yellow' }}/>:invitado.statusOutlook===1?<CheckCircle style={{ color: 'gray' }}/>:invitado.statusOutlook===0?<RemoveCircle color="secondary"/>:null}
                                                        </TableCell>
                                                    </>:
                                                    null
                                                }
                                                
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                            )}
                        </TableContainer>
                </Acordeon>
            </AcordeonContainer>
            <AcordeonContainer id='lugar-reunion'>
                <Acordeon imgSrc='/images/Icons/ubicacion.png' label='Lugar de reunión' margin={'0px 10px 0px 0px'} expanded={secAcordeon.secLugar} setExpanded={HandleExpand}seccion='secLugar'>
                    <AcordeonContainerBody marginBottom="20px" style={{flexWrap:"wrap"}}>
                        <ContainerTwoInputs flexDirection="column">
                            <Typography>Lugar</Typography>
                            <NativeSelect input={<BootstrapInput/>} style={{width:"100%"}} id="lugar-reunion" onChange={onChange} value={cREU_Lugar} className={`${cREU_Lugar!="" && cREU_Lugar!=undefined?'inputActive':''}`}>
                                <option value="">Seleccionar</option>
                                <option value="Hotel">Hotel</option>
                                <option value="Restaurante">Restaurante</option>
                                <option value="Oficina">Oficina</option>
                                <option value="Otro">Otro</option>
                            </NativeSelect>
                        </ContainerTwoInputs>
                        <ContainerTwoInputs flexDirection="column">
                            <Typography>País</Typography>
                                <NativeSelect input={<BootstrapInput/>} style={{width:"100%"}} id="pais-reunion" onChange={onChange} value={parseInt(aux_cPAI_Id,10)} className={`${aux_cPAI_Id!="0" && aux_cPAI_Id!=undefined?'inputActive':''}`}>
                                       {DataCountries&&
                                       DataCountries.map((countrie,index)=>(
                                       <option key={index} value={countrie.cPAI_Id}>{countrie.cPAI_NOMBRE}</option>
                                   ))}
                                </NativeSelect>
                        </ContainerTwoInputs>
                        <ContainerTwoInputs flexDirection="column">
                            <Typography>Estado</Typography>
                            <NativeSelect input={<BootstrapInput/>} style={{width:"100%"}} id="ciudad-reunion" onChange={onChange} value={parseInt(aux_cEDO_Id,10)}  className={`${aux_cEDO_Id!="0" && aux_cEDO_Id!=undefined?'inputActive':''}`}>
                                <option value="0">Seleccionar</option>
                                {DataCities&&
                                    DataCities.map((city,index)=>(
                                    <option key={index} value={city.cEDO_Id}>{city.cEDO_Nombre}</option>
                                    ))
                                }
                            </NativeSelect>
                        </ContainerTwoInputs>
                        <ContainerTwoInputs flexDirection="column">
                            <Typography>Ciudad</Typography>
                            <NativeSelect input={<BootstrapInput/>} style={{width:"100%"}} id="ciudades-reunion">
                                <option value="0">Seleccionar</option>
                                {dataCuidades&&
                                    dataCuidades.map((city,index)=>(
                                    <option key={index} value={city.cCIUD_Id}>{city.cCIUD_Nombre}</option>
                                    ))
                                }
                            </NativeSelect>
                        </ContainerTwoInputs>
                    </AcordeonContainerBody>
                    <AcordeonContainerBody marginBottom="20px">
                        <ContainerTwoInputs flexDirection="column">
                            <Typography>Zona horaria del país que visita</Typography>
                               <NativeSelect input={<BootstrapInput/>} style={{width:"100%"}} onChange={onChange} id="zona-horaria" value={sessionStorage.getItem('cREU_ZonaHoraria')}   className='inputActive'>
                                   <option value="America/Mexico_city">America/México City</option>
                                   {moment.tz.names().map((zona,index)=>(
                                       <option key={index} value={zona}>{zona}</option>
                                   ))}
                                </NativeSelect>
                        </ContainerTwoInputs>
                        <ContainerTwoInputs flexDirection="column">
                            <Typography>Hora de cita en país de visita</Typography>
                            <TextField id="cita-país" placeholder="14:00" variant="outlined" style={{width:"100%"}} value={HoraZona=='ida'?null:HoraZona} size="small"/>
                        </ContainerTwoInputs>
                    </AcordeonContainerBody>
                    <AcordeonContainer>
                        <Typography>Link de conferencia</Typography>
                        <TextField id="link-conferencia" fullWidth variant="outlined" placeholder="zoom.com/1234" onChange={onChange} value={linkConferencia==='null'?'':linkConferencia}  className={`${linkConferencia!="" && linkConferencia!=undefined?'inputActive':''}`}/>
                    </AcordeonContainer>
                </Acordeon>
            </AcordeonContainer>
            <AcordeonContainer id='invitados-reunion'>
                <Acordeon imgSrc='/images/Icons/equipo.png' label='Agregar invitados y asistentes en la reunión' margin="0px 10px 0px 0px" expanded={secAcordeon.secInvitados} setExpanded={HandleExpand}seccion='secInvitados'>
                    <AcordeonContainerBody marginBottom="20px">
                        <ContainerTwoInputs flexDirection="column" width="25%">
                                   <Typography>Clave</Typography>
                            <TextField id="clave-reunion-corredor" fullWidth variant="outlined" placeholder="234" onChange={onChange} type="number" value={aux_claveCorredor} disabled={disabledCorredor}   className={`${aux_claveCorredor!="" && !isNaN(aux_claveCorredor) ?'inputActive':''}`}/>
                        </ContainerTwoInputs>
                        <ContainerTwoInputs flexDirection="column" width="65%">
                            <Typography>Corredor</Typography>
                            <SearchInvitadosSirec data={[...Retrocessionary,...Corredor]} getIdCargos={getInvitadosSirec} tipo={1} disabledSearch={disabledCorredor}/>
                        </ContainerTwoInputs>
                    </AcordeonContainerBody>
                    <AcordeonContainerBody marginBottom='20px'>
                        <ContainerTwoInputs flexDirection="column" width="25%">
                                   <Typography>Clave</Typography>
                            <TextField id="clave-reunion-company" fullWidth variant="outlined" placeholder="234" onChange={onChangeCompany} type="number" value={aux_claveCompany} disabled={disabledCompany} className={`${aux_claveCompany!="" && !isNaN(aux_claveCompany)?'inputActive':''}`}/>
                        </ContainerTwoInputs>
                        <ContainerTwoInputs flexDirection="column" width="65%">
                            <Typography>Compañia</Typography>
                            <SearchInvitadosSirec data={Company} getIdCargos={getInvitadosSirec} tipo={2} disabledSearch={disabledCompany}/>
                        </ContainerTwoInputs>
                    </AcordeonContainerBody>
                    {useSizeDimmensions()>550?
                        (
                            <TableContainer component={Paper} className={tableClasses.container}>
                                <Table className={classes.table} aria-label="invitados table">
                                    <TableHead>
                                        <StyledTableCell></StyledTableCell>
                                        <StyledTableCell>Nombre</StyledTableCell>
                                        <StyledTableCell>Correo</StyledTableCell>
                                        <StyledTableCell>Cargo o puesto</StyledTableCell>
                                        <StyledTableCell><Email style={{color:"white"}}/></StyledTableCell>
                                    </TableHead>
                                    <TableBody>
                                    {CargosSirec&&
                                    CargosSirec.map((invitado,index)=>(
                                        <TableRow key={index}>
                                            {console.log(window.screen.width)}
                                            <TableCell style={{textAlign:"center"}}>
                                                <Checkbox color="default" onChange={(e)=>editEje(index,e.target.checked)}/>
                                            </TableCell>
                                            <TableCell style={{textTransform:"capitalize"}}>{invitado.cEje_Nombre}</TableCell>
                                            <TableCell>{invitado.cEje_Mail}</TableCell>
                                            <TableCell style={{textAlign:"center"}}>{invitado.cEje_Cargo}</TableCell>
                                            <TableCell style={{textAlign:"center"}}>
                                                <Checkbox icon={<RadioButtonUnchecked/>} checkedIcon={<RadioButtonChecked/>} color="default"/>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        ):
                        (
                            <TableContainer component={Paper} className={tableClasses.container} style={{width:'100%'}}>
                            <Table className={classes.table} aria-label="invitados table">
                                <TableHead>
                                    <StyledTableCell></StyledTableCell>
                                    <StyledTableCell>Contactos</StyledTableCell>
                                    <StyledTableCell><Email/></StyledTableCell>
                                </TableHead>
                                <TableBody>
                                    {CargosSirec&&
                                    CargosSirec.map((invitado,index)=>(
                                        <TableRow key={index}>
                                            <TableCell style={{textAlign:"center",padding:'5px'}}>
                                                <Checkbox color="default" onChange={(e)=>editEje(index,e.target.checked)}/>
                                            </TableCell>
                                            <TableCell style={{textAlign:"left",fontSize:'10px',padding:'5px'}}>{`${invitado.cEje_Nombre} ${invitado.cEje_Mail} ${invitado.cEje_Cargo}`}</TableCell>
                                            <TableCell style={{textAlign:"center",padding:'5px'}}>
                                                <Checkbox icon={<RadioButtonUnchecked/>} checkedIcon={<RadioButtonChecked/>} color="default"/>
                                            </TableCell>
                                        </TableRow>
                                    ))}    
                                </TableBody>  
                            </Table>
                        </TableContainer>
                        )
                
                    }
                    <ContainerTwoInputs justifyContent="flex-end" width='90%'>
                        <Typography style={{marginRight:"5px",color:'#9E9EBD'}}>Agregar registro nuevo</Typography>
                        <Icon>add_circle</Icon>
                    </ContainerTwoInputs>
                </Acordeon>
            </AcordeonContainer>
            <AcordeonContainer id='asistentes-reunion' >
                <Acordeon imgSrc="/images/Icons/Icon_asistentes_500-01.png" label=" Asistentes a la reunión" margin="0px 10px 0px 0px" expanded={secAcordeon.secAsistentes} setExpanded={HandleExpand}seccion='secAsistentes'>
                    <AcordeonContainerBody marginBottom="20px" flexDirection="column" alignItems="center">
                        <AcordeonContainerBody marginBottom="20px">
                            <ContainerTwoInputs flexDirection="column">
                                <Typography>Tipo de reunión</Typography>
                                <NativeSelect input={<BootstrapInput/>} style={{width:"100%"}} id='tipo-reunion' onChange={onChange} value={aux_cREU_TipoReunion} className={`${aux_cREU_TipoReunion!="" && aux_cREU_TipoReunion!=undefined?'inputActive':''}`}>
                                    <option value="">Seleccionar</option>
                                    {tipo_reunion.map((tipo,index)=>(
                                        <option key={index} value={tipo.title}>{tipo.title}</option>
                                    ))}

                                </NativeSelect>
                            </ContainerTwoInputs>
                            <ContainerTwoInputs flexDirection="column">
                            <Typography>Plantilla de mensaje</Typography>
                                <NativeSelect input={<BootstrapInput/>} style={{width:"100%"}} id='plantilla-mensaje' onChange={onChange} value={aux_cPLA_Id} className={`${aux_cPLA_Id!="" && aux_cPLA_Id!=undefined?'inputActive':''}`}>
                                    <option value="">Seleccionar</option>
                                    {DataMessage&&
                                       DataMessage.map((message,index)=>(
                                       <option key={index} value={message.cPLA_Id}>{message.cPLA_Nombre_}</option>
                                       ))}
                                </NativeSelect>
                            </ContainerTwoInputs>
                        </AcordeonContainerBody>
                        <TextareaAutosize rowsMin={10} value={textMessage} style={{width:"90%"}} onChange={onChange} id='text-mensaje'/>
                    </AcordeonContainerBody>
                </Acordeon>
            </AcordeonContainer>
            <AcordeonContainerBody marginTop="10px" style={{marginBottom:"50px"}}>
                <ContainerTwoInputs width="32%" margin="5px">
                    <BottonStyledSeccion backgroundColor="#DDDDDD" color="#fff" width="100%" height="40px" onClick={borrarData}>
                        Borrar 
                    </BottonStyledSeccion>
                </ContainerTwoInputs>
                <ContainerTwoInputs width="32%" margin="5px">
                    <BottonStyledSeccion backgroundColor="#C8C8C8" color="#fff" width="100%" height="40px" onClick={descartarData}>
                        Descartar 
                    </BottonStyledSeccion>
                </ContainerTwoInputs>
                    <ContainerTwoInputs width="32%" margin="5px" order='-2'>
                        <BottonStyledSeccion backgroundColor="#3898EC" color="#fff" width="100%" height="40px" onClick={guardarData}>
                            Guardar 
                        </BottonStyledSeccion>
                    </ContainerTwoInputs>
                </AcordeonContainerBody>
            <AcordeonContainer>
                <Acordeon imgSrc="/images/Icons/charla.png" label={<>Comentarios de viaje <b>(Próximamente)</b></> } margin="0px 10px 0px 0px">

                </Acordeon>
            </AcordeonContainer>
            <AcordeonContainer>
                <Acordeon imgSrc="/images/Icons/acuerdo.png" label={<>Acuerdos de viaje <b>(Próximamente)</b></>} margin="0px 10px 0px 0px">
                    
                </Acordeon>
            </AcordeonContainer>
        </Page>
    )
}


export default withRouter(ProgramarReunion);