import React, { useEffect, useState } from 'react';
import Homebar from './Homebar';
import { Page, PageContainer, Title, Subtitle,DivNavigationButton, AcordeonContainer } from './StylesComponents/common-styled';
import { navigation } from '../funciones-auxiliares/programar-reunion';
import newSolicitud from '../assets/images/icons/documento.svg';
import processSolicitud from '../assets/images/icons/lupa.svg';
import comprobarGasto from '../assets/images/icons/calculadora.svg';
import Create from './Viaticos/Create';
import List from './Viaticos/List';
import NewSolicitud from './Viaticos/NewSolicitud';
import './../assets/styles/css/master.css';
import ButtonNavigation from './Viaticos/ButtonNavigation';
import Footer from './Viaticos/Footer';
import {Redirect,useHistory} from 'react-router-dom';
import ProgressSolicitud from './Viaticos/ProgressSolicitud';



const Viaticos = ({ session}) => {
    //Hooks
    const history=useHistory();
    console.log('Secccionnn=000>>>',session.obtenerUsuario);
    
    const [activeButtons,setActiveButtons]=useState(
        {
            first:true,
            second:false,
            third:false,
        })
    const [section, setSection] = useState(<Create />);
    const [sectionActive, setSectionActive] = useState('new')
    //Handlers
    const handleSection = (section) => {
        setSectionActive(section)
        if (section === 'new') {
            setSection(<Create />)
        } else if (section === 'list') {
            setSection(<List setSection={handleSection} />)
        } else {
            setSection(section);
        }
    }
    //
    const handleChange = e => {
        console.log(e)
    }

    useEffect(()=>{
        session.obtenerUsuario?console.log('hola'):history.push('/');
    })
    return (
        <>
        <Page>
            <Homebar navigation={navigation} />
            <PageContainer>
                <Title>Viáticos</Title>
                <Subtitle>Solicita, registra y autoriza los viáticos de tus viajes.</Subtitle>
            </PageContainer>
            <AcordeonContainer flexDirection="wrap" justifyContent='space-around'>
                <DivNavigationButton width='20%' alignSelf='end'>
                    <ButtonNavigation text='Nueva solicitud' img={newSolicitud}     backgroundColor={activeButtons.first?'#3898EC':null} border=   {activeButtons.first?'#3898EC':null} color={activeButtons.first?  '#FFF':null} onClick={()=>setActiveButtons({first:true,second:false,     third:false})}/>
                    <ButtonNavigation text='Solicitudes en proceso' img=    {processSolicitud} backgroundColor={activeButtons.second?      '#3898EC':null} border={activeButtons.second?'#3898EC':null} color=   {activeButtons.second?'#FFF':null} onClick={()=>setActiveButtons(     {first:false,second:true,third:false})}/>
                    <ButtonNavigation text='Comprobar gastos' img={comprobarGasto}      backgroundColor={activeButtons.third?'#3898EC':null} border=   {activeButtons.third?'#3898EC':null} color={activeButtons.third?     '#FFF':null} onClick={()=>setActiveButtons({first:false,   second:false,third:true})}/>
                </DivNavigationButton>
                {
                    activeButtons.first&&
                    <NewSolicitud cEMP_Id={session.obtenerUsuario}/>
                }
                {
                    activeButtons.second&&
                    <ProgressSolicitud cEMP_Id={session.obtenerUsuario}/>
                }
                
            </AcordeonContainer>
        </Page>
        <Footer/>
        </>
    )
}

export default Viaticos;