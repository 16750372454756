import React,{useEffect, useState} from 'react';
import CardProgress from './CardProgress';
import { GET_TRAVEL_EXPENSES_BY_IDUSER } from '../../query/query';
import { useLazyQuery } from 'react-apollo';

const ProgressSolicitud=({cEMP_Id})=>{
  const [viaticos,setViaticos]=useState([]);

  const [getTravelExpenses]=useLazyQuery(
    GET_TRAVEL_EXPENSES_BY_IDUSER,{
      onCompleted({getTravelExpensesbyUser}){
        const aux=JSON.parse(getTravelExpensesbyUser)
        setViaticos(aux);
        console.log(aux);
      }
    }
  )

  useEffect(()=>{
    getTravelExpenses({variables:{cEMP_Id:cEMP_Id.cEMP_Id}})
  },[]);

  return(
    <div style={{width:'70%',minHeight:'65vh',display:'flex',flexWrap:'wrap',justifyContent:'space-around'}}>
      {
        viaticos&&
        viaticos.map(data=>{
          return(
            <CardProgress idViaticos={data.cVIT_Id} nombre={cEMP_Id.cEMP_Nombre} idReunion={data.cREU_Id} fechas='23 Sep 2020 - 28 Sep 2020' pais='Londres'/>
          )
        }
          
        )
      }
      
    </div>
    
  )
}

export default ProgressSolicitud;