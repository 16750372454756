import { TextareaAutosize} from '@material-ui/core';
import React, { useState } from 'react';
import ToggleButtons from './ToggleButons';

const FormObservaciones=({title,cEMP_Id})=>{
  const [observations,setObservations]=useState([{
    cOBS_Descripcion:'',
    cEMP_Id:cEMP_Id,
  }])

  const handleChange=(e)=>{
    setObservations(observations.map((data)=>{
        data={...data,cOBS_Descripcion:e.target.value}
      return data;
    }))
    sessionStorage.setItem('observations',JSON.stringify(observations.map((data)=>{
        data={...data,cOBS_Descripcion:e.target.value}
      return data;
    })))
  }
  return(
    <>
    <div style={{marginTop:'20px'}}>
        <h3 style={{textAlign:'left'}}>{title}</h3>
    </div>
    <TextareaAutosize rowsMin={10} style={{width:"90%"}}  id='text-mensaje' onChange={handleChange}/>
    <ToggleButtons/>
  </>
  )
}

export default FormObservaciones;