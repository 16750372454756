import React, { Component} from 'react';
import {withRouter, Redirect} from 'react-router-dom';
import './components.css';
import {Mutation}from 'react-apollo';
import {AUTENTICATE_USER} from '../mutation/mutation';
import bcrypt from 'bcryptjs';

const initialState={
    user:'',
    password:'',
}
class Login extends Component{
    state = {
        ...initialState
    }

     actualizarState = e => {
        const { name, value} = e.target;
        this.setState({
            [name] : value
        })
     }


    limpiarState = () => {
         this.setState({...initialState});
    }

    iniciarSesion =async (e, usuarioAutenticar) => {
        e.preventDefault();
        console.log(usuarioAutenticar);
        const salt=await bcrypt.genSalt(10);
        const hashPassword=await bcrypt.hash(this.state.password,salt);
        this.setState({
            pass:hashPassword
        })
        usuarioAutenticar().then(async({data})=>{
            if(!data.autenticarUsuario.token){
                alert(data.autenticarUsuario.message)
                this.limpiarState()
            }
            else{
                localStorage.setItem('token',data.autenticarUsuario.token)
                await this.props.refetch()
                this.limpiarState()
                setTimeout(()=>{
                    this.props.history.push('/secciones')
                },300)
            }
            
        })
     }

     validarForm = () => {
        const {user, password} = this.state;

        const noValido = !user || !password;
        return noValido;
     }
    render(){
        const {user,password,pass}=this.state;
        let userAutenticade= (this.props.session.obtenerUsuario)?<Redirect to="/secciones"/>:null
        return(
            <div className="login">
                <div className="login-container">
                    {userAutenticade}
                    <Mutation mutation={AUTENTICATE_USER} variables={{user,password:pass}}>
                        {(usuarioAutenticar,{loading,error,data})=>{
                            return(
                                <form onSubmit={e=>this.iniciarSesion(e,usuarioAutenticar)} className="form-container" style={{alignItems:"center"}}>
                                    {error && alert(error)}
                                    <img src="/images/logo_patria.png" width={133} alt="Logo"/>
                                    <h2>Iniciar sesión</h2>
                                    <input name="user" placeholder="Ingresa tu email" type="email" className="login-input" onChange={this.actualizarState} value={user}/>
                                    <input name="password" placeholder="Contraseña" type="password" className="login-input" onChange={this.actualizarState} value={password}/>
                                    <button name="submit" type="submit" className="login-submit" disabled={loading || this.validarForm}>Enviar</button>
                                    <a href="#" className="login-link">¿Olvidaste tu Contraseña</a>
                                </form>
                            )
                        }}
                    </Mutation>
                </div>
            </div>    
        )
    }
}

    export default withRouter(Login);
 