import React,{Component,createContext} from 'react';
import {withRouter} from 'react-router-dom';
import { PublicClientApplication } from '@azure/msal-browser';

import { config } from '../config/config';
import { getUserDetails } from '../services/GraphService';
export const MyContext=createContext()

function normalizeError(error){
    let normalizedError={};
    if(typeof(error)==='string'){
        const errParts=error.split('|');
        normalizedError=errParts.length>1?{message:errParts[1],debug:errParts[0]}:{message:error}
    }
    else{
        normalizedError={
            message:error.message,
            debug:JSON.stringify(error)
        }
    }
    return normalizedError;
}

function isInteractionRequired(error){
    if(!error.message || error.message.length<=0){
        return false;
    }
    return (
        error.message.indexOf('consent_required')>-1 ||
        error.message.indexOf('interaction_required') > -1 ||
        error.message.indexOf('login_required') > -1 ||
        error.message.indexOf('no_account_in_silent_request') > -1
    )
}

const publicClientApplication=new PublicClientApplication({
    auth:{
        clientId:config.appId,
        redirectUri:config.redirectUri,
    },
    cache:{
        cacheLocation:"sessionStorage",
        storeAuthStateInCookie:true,
    }
})

class MyProvider extends Component{
    //history=useHistory();
    state={
        cREU_Id: null,
        cEMP_Id: null,
        cEDO_Id: null,
        cPAI_Id: null,
        cPLA_Id: null,
        cREU_ConferenciaURL: "",
        cREU_FechaInicio: "",
        cREU_HoraInicio: "",
        cREU_FechaFin: "",
        cREU_HoraFin: "",
        cREU_Lugar: "",
        cREU_Titulo: "",
        cREU_ZonaHoraria:"",
        cREU_FechaActualPais: "",
        cREU_HoraActualPais: "",
        cREU_TipoReunion: "",
        cREU_IdOutlook:"",
        invitadosReunion: [],
        rEjexORG: [],
        AuthComponentProps:{
            error: null,
            isAuthenticated: null,
            user: null,
            token:null,
            expiresOn:null
        }
    }

    clearState=()=>{
        this.setState({
            ...this.state,
            cREU_Id: null,
            cEMP_Id: null,
            cEDO_Id: null,
            cPAI_Id: null,
            cPLA_Id: null,
            cREU_ConferenciaURL: "",
            cREU_FechaInicio: "",
            cREU_HoraInicio: "",
            cREU_FechaFin: "",
            cREU_HoraFin: "",
            cREU_Lugar: "",
            cREU_Titulo: "",
            cREU_ZonaHoraria:"",
            cREU_FechaActualPais: "",
            cREU_HoraActualPais: "",
            cREU_TipoReunion: "",
            cREU_IdOutlook:"",
            invitadosReunion: [],
            rEjexORG: []
        })
    }

    getData=(data)=>{
        this.setState(data)
    }
    getDataAuthenticated=(data)=>{
        this.setState({
            ...this.state,
            ...data
        })
    }

    async login(){
        try {
            await publicClientApplication.loginPopup(
                {
                    scopes:config.scopes,
                    prompt:'select_account',
                });
            await this.getUserProfile();
        } catch (error) {
            sessionStorage.setItem('error',JSON.stringify(
                {
                    error: normalizeError(error),
                    isAuthenticated: false,
                    user: {},
                    token:null,
                    expiresOn:null
                }
            ));
            this.getDataAuthenticated({
                AuthComponentProps:{
                    error: normalizeError(error),
                    isAuthenticated: false,
                    user: {},
                    token:null,
                    expiresOn:null
                }
            })
            console.log(error)
        }
    }

    
    logout(){
        publicClientApplication.logout();
    }

    async getAccessToken(scopes){
        try {
            const accounts=publicClientApplication.getAllAccounts();
            if(accounts.length<=0) throw new Error('login_required');
            const silentResult=await publicClientApplication.acquireTokenSilent(
                {
                    scopes:scopes,
                    account:accounts[0]
                });
            console.log('Token_____------->>>----->>>',silentResult)
            //return silentResult.accessToken;
            return silentResult;
        } catch (error) {
            if(isInteractionRequired(error)){
                const interactiveResult=await publicClientApplication.acquireTokenPopup({scopes:scopes})
                return interactiveResult.accessToken;
            }
            else{
                throw error;
            }
        }
    }

    async getUserProfile(){
        try {
            const accessToken=await this.getAccessToken(config.scopes);
            console.log(accessToken)
            if(accessToken){
                const user=await getUserDetails(accessToken.accessToken);
                localStorage.setItem('user',JSON.stringify(
                    {
                        error: null,
                        isAuthenticated: true,
                        user: {
                            displayName:user.displayName,
                            email:user.mail || user.userPrincipalName,
                            timeZone:user.mailboxSettings.timeZone,
                            timeFormat:user.mailboxSettings.timeFormat
                        },
                        token:accessToken.accessToken,
                        expiresOn:accessToken.expiresOn
                    }))
                    this.getDataAuthenticated({
                        AuthComponentProps:{
                            error: null,
                            isAuthenticated: true,
                            user: {
                                displayName:user.displayName,
                                email:user.mail || user.userPrincipalName,
                                timeZone:user.mailboxSettings.timeZone,
                                timeFormat:user.mailboxSettings.timeFormat
                            },
                            token:accessToken.accessToken,
                            expiresOn:accessToken.expiresOn
                        }
                    })
            }
        }
        catch (error) {
            sessionStorage.setItem('error',JSON.stringify(
                {
                    error: normalizeError(error),
                    isAuthenticated: false,
                    user: {},
                    token:null,
                    expiresOn:null

                }
            ));
            this.getDataAuthenticated({
                AuthComponentProps:{
                    error: normalizeError(error),
                    isAuthenticated: false,
                    user: {},
                    token:null,
                    expiresOn:null
                }
            })
        }
    }

    

    setErrorMessage(message, debug) {
        this.setState({
          error: { message: message, debug: debug }
        });
      }

    

    componentDidMount(){
        const acounts=publicClientApplication.getAllAccounts();
        if(acounts && acounts.length>0){
            this.getUserProfile();
        }
    }

    render(){
        const {
            state,
            clearState,
            getData,
            login,
            logout,
            getAccessToken,
            getUserProfile,
            getDataAuthenticated,
            setErrorMessage,
        }=this
        return(
            <MyContext.Provider
            value={{
                state,
                clearState,
                getData,
                login,
                logout,
                getAccessToken,
                getUserProfile,
                getDataAuthenticated,
                setErrorMessage
            }}>
                {this.props.children}
            </MyContext.Provider>
        )
    }
}


export default withRouter(MyProvider);

// EwBwA8l6BAAUO9chh8cJscQLmU+LSWpbnr0vmwwAAfoFgF5NrrZ1R8ALSK7C6BryZNrN+50fICFbNNgdH8y6tb+1XjX8FyToUnWC+jB8qicEPxbpWSXB61PnXovwaqHwl7pr8u5gZL1QHFaAxpA1DoTKZPUULxO6+ZfH+X/nc8hlgnlCE/AIADxtg8u+P2aNqkrjsUJwmd8MowAZ19x0r/Y0YkQpkDcSei+RbxrIZDSdgYl87Nq+C/OyrRxZYDvD2fuHv7gYulcH5rIZiTQ+ZiQo4cpVn9rMhG/N2/jvk4B3Prgm7xXyPR1Z9hDxefNB/PfD2rB/hhpNh6ipZR5g78FHR7ajccSxFhsXh+NyjW4gNJ3eN+7OwO0t4YtaGB4DZgAACDgz7zhtvc18QAKpPNGK77EBQ80NbGgTWTe0QQelVRhG+/ucW6Vjq/p5/NVti1FVVQaxDu5JdD/DVvfGATmhZTMP90xi/+YB0lIsyZtp9KGIROS4wVvxLTHhGxv4UFD2G5jKliWdwcg0+RjtcdvLZbhTymrrgzRXF5ZzkoQ+8s0IGKNr2te1Bd25TG/NQ0zdOID10xziaQxiMyvUo4hEszqaEH4NzhnTmt28K5sbFwKqgordo6KgkxFfSRuPSdp7QC72OGCLHFT81SZf+0mwfVkI9KPxKRkMk4JZUWl/9oUIjEXHAfWSKo4tO8D8uz0zCd2/JKccGau8PYY4MGTzRh9W28k//mon/6b3GZfibfuTwkTm9kVyu045M8ssa89y0XSnsoT1C6zP15ZBlOdUc7G/gw+FhWHAimiJg3n+CWsHaccoDiBpeAj9EruyrXvbG9rGcGFq54Yylm2DWHfqhu+ChO/9KeJ6LM0LR21zOZcGjjs6a7evXbQAYZMDbDGsxDDE2INs/CoWJdUQghAZE9fR2WqUTVOX5u0LAt6dhmOPqWkatJdDOw0VGAQopeseX0LBeTOOs4bzuhaag/rKIFIjKWZuhRzKTzyDUx6vTk8H6U21zDkFzGzoDfCA8j6N8V5B9cvmq86XhkDiOMEFDH1OTxNQYr596/IHqOXRIZgVXKb7i1/i/PvEEoKcpsGpYwMXmf0qXVPPEIotAAt7ThNb/RWLD0PQhPsYVeLw3R98xwgGHhx2fRohvI7kKHWeBfXbi3q3fKjyKeCFAg==