import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import { PageContainer, Subtitle , ContainerTwoInputs } from '../StylesComponents/common-styled';

import Card from './Card';
import calendario from '../../assets/images/icons/calendario.svg';
import user from '../../assets/images/icons/usuario.svg';
import ButtonsInformacion from './ButtonsInformacion';
import FormTravels from './FormTravels';
import FormAlojamiento from './FormAlojamiento';
import FormMateriales from './FormMateriales';
import FormObservaciones from './FormObservaciones';
import ReunionComplete from './ReunionComplete';
import ButtonNavigation from './ButtonNavigation';
import { useLazyQuery,useMutation } from 'react-apollo';
import { GET_EVENTOSBYVIATICOS } from '../../query/query';
import {SAVE_VIATICOS} from '../../mutation/mutation';
import { AddCircle } from '@material-ui/icons';
import { green } from '@material-ui/core/colors';



const useNewSolicitud=({cEMP_Id})=>{
  const [reuniones,setReuniones]=useState([]);
  const [data,setData]=useState({titulo:'',texto1:'',texto2:'',texto3:''});
  const [data2,setData2]=useState({titulo:'',texto1:'',texto2:'',texto3:''});
  const [value,setValue]=useState('Ida y vuelta');
  const [tramos,setTramos]=useState(
    [
      {
        titulo:'Tramo 1'
      },
    ]
  )
  const [viaje,setViaje]=useState(
    [
      {
        origen:'',
        destino:'',
        aerolinea:'',
        vuelo:'',
        cotizacion:'',
        fecha:'',
        horario:'',
        precio:''
      },
      {
        origen:'',
        destino:'',
        aerolinea:'',
        vuelo:'',
        cotizacion:'',
        fecha:'',
        horario:'',
        precio:''
      },
    ]);
    const [alojamiento,setAlojamiento]=useState(
      [
        {
          hotel:'',
          urlCotizacion:'',
          fecha:'',
          precio:'',
        },
      ]
    )

  const [getEventsByViaticos]=useLazyQuery(GET_EVENTOSBYVIATICOS,{
    onCompleted({getReunionbyViaticos}){
      setReuniones(getReunionbyViaticos);
      console.log(getReunionbyViaticos);
    }
  });
  const setViaje1=()=>{
    setViaje(
      [
        {
          origen:'',
          destino:'',
          aerolinea:'',
          vuelo:'',
          cotizacion:'',
          fecha:'',
          horario:'',
          precio:''
        },
      ]
    )
  }

  
  const setViaje2=()=>{
    setViaje([
      {
        origen:'',
        destino:'',
        aerolinea:'',
        vuelo:'',
        cotizacion:'',
        fecha:'',
        horario:'',
        precio:''
      },
      {
        origen:'',
        destino:'',
        aerolinea:'',
        vuelo:'',
        cotizacion:'',
        fecha:'',
        horario:'',
        precio:''
      },
    ])}

  const setALojamientoAux=()=>{
    setAlojamiento(
    [
      {
        hotel:'',
        urlCotizacion:'',
        fecha:'',
        precio:'',
      },
    ])
  }

  const handleTipoViatico=(tipo)=>{
    setValue(tipo);
    setTramos([{titulo:'Tramo 1'}]);
    if(tipo==="Ida y vuelta"){
      sessionStorage.setItem('cTIP_Id',1);
      sessionStorage.removeItem('travels');
      sessionStorage.removeItem('accommodation');
      sessionStorage.removeItem('materials');
      sessionStorage.removeItem('ITrequiremen');
      sessionStorage.removeItem('promotionals');
      setViaje2();
      setALojamientoAux();
    }else if(tipo==="Solo ida"){
      sessionStorage.setItem('cTIP_Id',2);
      sessionStorage.removeItem('travels');
      sessionStorage.removeItem('accommodation');
      sessionStorage.removeItem('materials');
      sessionStorage.removeItem('ITrequiremen');
      sessionStorage.removeItem('promotionals');
      setViaje1();
      setAlojamiento([]);
      setALojamientoAux();
    }else {
      sessionStorage.setItem('cTIP_Id',3);
      sessionStorage.removeItem('travels');
      sessionStorage.removeItem('accommodation');
      sessionStorage.removeItem('materials');
      sessionStorage.removeItem('ITrequiremen');
      sessionStorage.removeItem('promotionals');
      sessionStorage.removeItem('observations');
      setViaje1();
      setALojamientoAux();
    }
  }

  
  const handleChange=(e)=>{
    setViaje(viaje.map((data,index)=>{
      if(index==e.target.id){
        data={...data,[e.target.name]:e.target.value}
      }
      return data;
    }))
    sessionStorage.setItem('travels',JSON.stringify(viaje.map((data,index)=>{
      if(index==e.target.id){
        data={...data,[e.target.name]:e.target.value}
      }
      return data;
    })))
  }


  const handleChangueInput=(value,name,id)=>{
    setViaje(viaje.map((data,index)=>{
      if(index==id){
        data={...data,[name]:value}
      }
      return data;
    }))
    sessionStorage.setItem('travels',JSON.stringify(viaje.map((data,index)=>{
      if(index==id){
        data={...data,[name]:value}
      }
      return data;
    })))
  }

  const handleChangeAlojamiento=(e)=>{
    setAlojamiento(alojamiento.map((data,index)=>{
      if(index==e.target.id){
        data={...data,[e.target.name]:e.target.value}
      }
      return data;
    }))
    sessionStorage.setItem('accommodation',JSON.stringify(alojamiento.map((data,index)=>{
      if(index==e.target.id){
        data={...data,[e.target.name]:e.target.value}
      }
      return data;
    })))
  }




  const addEvent=()=>{
    setTramos([...tramos,{titulo:`Tramo ${tramos.length+1}`}])
    setViaje([...viaje,
      {
        origen:'',
        destino:'',
        aerolinea:'',
        vuelo:'',
        cotizacion:'',
        fecha:'',
        horario:'',
        precio:''
      },
    ])
    setAlojamiento([...alojamiento,
      {
        hotel:'',
        urlCotizacion:'',
        fecha:'',
        precio:'',
      },
    ])
  }

  const editEvent=(evento)=>{
    setData({
      titulo:evento.cREU_Titulo,
      texto1:`${evento.cREU_FechaInicio}-${evento.cREU_FechaFin}`,
      texto2:`${evento.Pais}, ${evento.Estado}`,
      texto3:evento.cREU_TipoReunion
    })
    setData2({
      titulo:evento.Nombre_Empleado,
      texto1:`No. empleado ${cEMP_Id.cEMP_Id}`,
      texto2:'Suscripción',
      texto3:'Patria RE'
    })
    sessionStorage.setItem('cREU_Id',evento.cREU_Id)
  }

  const [saveProducts] = useMutation(SAVE_VIATICOS, {
    onCompleted({ createTravelExpenses }) {
        console.log(createTravelExpenses);
    },
    onError({ graphQLErrors, networkError }) {

        graphQLErrors.map(({ message, locations, path }) =>
            console.log(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            ),
        );

        if (networkError) console.log(`[Network error]: ${networkError}`);
    }
});

  const save=()=>{
    const travels= JSON.parse(sessionStorage.getItem('travels'));
    const accommodation=JSON.parse(sessionStorage.getItem('accommodation'));
    const materials=JSON.parse(sessionStorage.getItem('materials'));
    const ITrequirement=JSON.parse(sessionStorage.getItem('ITrequiremen'));
    const promotional=JSON.parse(sessionStorage.getItem('promotionals'));
    const observations=JSON.parse(sessionStorage.getItem('observations'));
    const input={
      cREU_Id:parseInt(sessionStorage.getItem('cREU_Id'),10),
      cTIP_Id:parseInt(sessionStorage.getItem('cTIP_Id'),10),
      travels:travels.map(({origen,destino,aerolinea,vuelo,cotizacion,fecha,horario,precio},index)=>{
        return{
          cVIA_Origen: origen,
          cVIA_Destino: destino,
          cVIA_Areolinea: aerolinea,
          cVIA_NoVuelo: vuelo,
          cVIA_URLVueloCot: cotizacion,
          cVIA_HorarioVuelo: horario.split(' ').join(''),
          cVIA_FechaVuelo: fecha.split('/').join('-'),
          cVIA_PrecioVuelo: precio,
          cTVI_Id: index % 2 === 0 && index !== travels.length ? 1 : 2
        }
      }),
      accommodation:accommodation.map(({hotel,urlCotizacion,fecha,precio},index)=>{
        return {
          cALO_Hotel: hotel,
          cALO_URLAlojamientoCot: urlCotizacion,
          cALO_Precio: precio,
        }
      }),
      materials:materials.map(({cMAT_Descripcion})=>cMAT_Descripcion),
      ITrequirement:ITrequirement.map(({cREQT_Descripcion})=>cREQT_Descripcion),
      promotional:promotional.map(({cPRO_Descripcion})=>cPRO_Descripcion),
      observations:[
        {
          cOBS_Descripcion:observations[0].cOBS_Descripcion,
          cEMP_Id:observations[0].cEMP_Id
        }
      ]
    }

    console.log(input);
    saveProducts({ variables: { input } })
  }

  useEffect(()=>{
    sessionStorage.clear();
    getEventsByViaticos({variables:{cEMP_Id:cEMP_Id.cEMP_Id,cREU_FechaInicio:new Date().toISOString().slice(0,10)}})
    sessionStorage.setItem('cTIP_Id',1)
  },[])
  return(
    <PageContainer marginTop='20px' width='70%'>
      <Subtitle color='#000'>Registra los viáticos de tu viaje</Subtitle>
      <ContainerTwoInputs flexDirection='column' width='25%' margin='40px 0px 0px 0px'>
        <Typography>ID o título de la reunión</Typography>
        <ReunionComplete Events={reuniones} editEvent={editEvent}/>
      </ContainerTwoInputs>
      <ContainerTwoInputs width='100%' margin='20px 0px'>
        <Card data={data} img={calendario}/>
        <Card data={data2} img={user}/>
      </ContainerTwoInputs>
      <h3>Importante</h3>
      <p>Antes de continuar con el registro de tu viaje no olvides visitar esta página para conocer las recomendaciones para tu viaje</p>
      <a href='https://guiadelviajero.sre.gob.mx/index.php/informacion-por-destino' style={{color:'#3898EC',textAlign:'center'}}>Visitar Guia del viajero</a>
      <ButtonsInformacion title='Información de transporte' text1='Ida y vuelta' text2='Solo ida' text3='Múltiples destinos' handleType={handleTipoViatico}/>
      {
        value==='Múltiples destinos'?null:<FormTravels title='Viaje de ida' handleChangue={handleChange} id={0} valueInputs={viaje} handleChangueSelect={handleChangueInput}/>
      }
      {
        value==='Ida y vuelta'?<FormTravels title='Viaje de vuelta' handleChangue={handleChange} id={1} valueInputs={viaje} handleChangueSelect={handleChangueInput}/>:null
      }
      {
        value==='Múltiples destinos'?
        <>
          {
            tramos.map((tramo,index)=><FormTravels title={tramo.titulo} id={index} valueInputs={viaje} handleChangue={handleChange} handleChangueSelect={handleChangueInput}/>)
          }
          <div style={{display:'flex',justifyContent:'flex-end',width:'90%'}}>
            <ContainerTwoInputs width='140px'>
              <AddCircle style={{color:green[500]}} onClick={addEvent}/>
              <p>Agregar tramo</p>
            </ContainerTwoInputs>
          </div>
        </>:null

      }
      {
        value==='Múltiples destinos'?
        <>
          {
            tramos.map((tramo,index)=><FormAlojamiento title={`Alojamiento - ${tramo.titulo}`} id={index} valueInputs={alojamiento} handleChangue={handleChangeAlojamiento}/>)
          }
          <div style={{display:'flex',justifyContent:'flex-end',width:'90%'}}>
            <ContainerTwoInputs width='140px'>
              <AddCircle style={{color:green[500]}} onClick={addEvent}/>
              <p>Agregar tramo</p>
            </ContainerTwoInputs>
          </div>
        </>
        :<FormAlojamiento title='Información de alojamiento' id={0} valueInputs={alojamiento} handleChangue={handleChangeAlojamiento}/>
      }
      <FormMateriales title='Equipo o materiales para viaje' cambio={value}/>
      <FormObservaciones title='Observaciones' cEMP_Id={cEMP_Id.cEMP_Id}/>
      <div style={{marginTop:'20px',justifyContent:'center',display:'flex'}}>
      <ButtonNavigation text='Guardar' width='45%' backgroundColor='#3898EC' border='none' color='#fff' justifyContent='center' onClick={save}/>
    </div>
    </PageContainer>
  )
}

export default useNewSolicitud;