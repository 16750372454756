import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {RootSession} from './App';
import * as serviceWorker from './serviceWorker';
import ApolloClient, { InMemoryCache } from 'apollo-boost';
import {ApolloProvider} from 'react-apollo';

const client=new ApolloClient({
  //uri:'https://sandbox-patria.alimx.mx/api',
  uri:'https://dev-patria.alimx.mx/api',
  //uri:'http://localhost:8000/graphql',
  fetchOptions:{
    credentials:'include'
  },
  request:operation=>{
    const token=localStorage.getItem('token');
    console.log(token)
    operation.setContext({
      headers:{
        authorization:token
      }
    })
  },
  cache:new InMemoryCache({
    addTypename:false
  }),
  onError:({networkError,graphQLErrors})=>{
    console.log('graphQLErrors',graphQLErrors);
    console.log('networkError',networkError);
  }
})
ReactDOM.render(
     <ApolloProvider client={client}>
        <RootSession/>
     </ApolloProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
