import React,{useState} from 'react';
import {TextField} from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab';

const ReunionComplete=({Events,Event,editEvent})=>{
  const [value,setValue]=useState(Event);
  return(
    <>
      <Autocomplete
        freeSolo
        inputValue={value}
        options={Events}
        getOptionLabel={(option)=>`${option.cREU_Id} ${option.cREU_Titulo}`}
        onChange={(event,newValue)=>{
          newValue!=null?setValue(newValue):setValue({});
          if(newValue!==null)editEvent(newValue);
        }}
        renderInput={(params)=>(
          <TextField
            {...params}
            onChange={(e)=>setValue(e.target.value)}
            margin="normal"
            variant="outlined"
            InputProps={{...params.InputProps,type:'search'}}
            id="search-invitados"
          />
        )}
        />
    </>
  )
}

export default ReunionComplete;