import React, { useState } from 'react';
import { Button, Typography, TextField, Radio, RadioGroup, FormControlLabel } from '@material-ui/core';
import { Div, Subtitle, DivRow, AcordeonContainer } from './../StylesComponents/common-styled';
import useForm from './../../helpers/hooks/useForm';

const inputsViaticos = {
    origen: '',
    destino: '',
    aereolina: '',
    noVuelo: '',
    urlVuelo: '',
    hoarioVuelo: '',
    precioVuelo: ''
}

const inputsAlojamiento = {
    hotel: '',
    link: '',
    price: ''
}

const initialStateViaticos = {
    transport: 'idaVuelta',
    meet: {
        idMeet: '',
        dateIni: '23 Sept 2020',
        dateEnd: '28 Sept 2020',
        country: 'Londón',
        city: 'Gran Bretaña',
        place: 'Comida'
    },
    travels: [{
        title: "Viaje de ida",
        inputs: inputsViaticos,
    },
    {
        title: "Viaje de Vuelta",
        inputs: inputsViaticos,
    }],
    accommodations: [{
        inputs: inputsAlojamiento
    }]
}



const Create = () => {
    const { form, setForm, suscribe } = useForm({ initialState: initialStateViaticos });
    //Handlers
    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }
    return (
        <AcordeonContainer>
            <Typography>ID de reunión</Typography>
            <TextField fullWidth placeholder='Ingresa ID de la reunión' name="idMeet" value={form.meet.idMeet} onChange={handleChange} variant="outlined" />
            <Typography>Seguimiento resultados del primer semestre del 2020</Typography>
            <DivRow>
                <Div w="25%" text="center">
                    <img style={{ width: '45px' }} src={require('./../../assets/images/icons/calendario.png')} />
                </Div>
                <Div w="75%">
                    <Typography>{form.meet.dateIni} - {form.meet.dateEnd}</Typography>
                    <Typography>{form.meet.country}, {form.meet.city}</Typography>
                    <Typography>{form.meet.place}</Typography>
                </Div>
            </DivRow>
            <Div w="100">
                <Typography>César Fuentes Ayala</Typography>
            </Div>
            <DivRow>
                <Div w="25%" text="center">
                    <img style={{ width: '45px' }} src={require('./../../assets/images/icons/user.png')} />
                </Div>
                <Div w="75%">
                    <Typography>23 Sept 2020 - 28 Sept 2020</Typography>
                    <Typography></Typography>
                    <Typography>Comida</Typography>
                </Div>
            </DivRow>
            <Div>
                <Typography>Importante</Typography>
                <Typography>Antes de continuar con el registro de tu viaje no olvides visitar esta página para conocer
                        las recomendaciones para tu viaje.</Typography>

                <a href="#">Visitar Guia del viajero</a>
            </Div>
            <Div>
                <Subtitle>Información de transporte</Subtitle>
                <Div>
                    <RadioGroup aria-label="quiz" name="transport" value={form.transport} onChange={handleChange}>
                        <FormControlLabel value="idaVuelta" control={<Radio color="default" />} label="Ida y Vuelta" />
                        <FormControlLabel value="ida" control={<Radio color="default" />} label="Solo ida" />
                        <FormControlLabel value="multiple" control={<Radio color="default" />} label="Multiples destinos" />
                    </RadioGroup>
                </Div>
            </Div>
            <Div>
                <h1>Registra los viáticos de tu viaje</h1>
            </Div>
        </AcordeonContainer>
    );
}

export default Create;