export const navigation=[{
  title:'Reuniones',
  route:'/secciones/reuniones'
},
{
  title:'Snapshot',
  route:'/secciones/snapshots'
},
{
  title:'Suscripción',
  route:'/secciones/suscripcion-siniestro'
},
{
  title:'Acuerdos',
  route:'/secciones/acuerdos-viaje'
},
{
  title:'Secciones',
  route:'/secciones'
},
]

export const tipo_reunion=[
  {
      title:'Visita a oficinas'
  },
  {
      title:'Desayuno,Almuerzo o Cena de Trabajo en Restaurante'
  },
  {
      title:'Juntas de Trabajo en Foros Sectoriales'
  },
  {
      title:'Llamada Telefonica'
  },
  {
      title:'Video Conferencia'
  },
  {
      title:'Encuentro Casual No Progamado'
  },
  {
      title:'Otro'
  },
]

const formatDate=(day)=>{
  if(day<10){
      day='0'+day;
  }
  return day;
}
export const fechaActual=()=>{
  let date=new Date();
  let dd=date.getDate();
  let mm=date.getMonth()+1;
  let yyyy=date.getFullYear();

  dd=formatDate(dd);
  mm=formatDate(mm);

  return `${yyyy}-${mm}-${dd}`;
}
export const horaActual=()=>{
  let date=new Date();
  let hh=date.getHours();
  let mm=date.getMinutes();

  hh=formatDate(hh);
  mm=formatDate(mm);
 console.log(`${hh}:${mm}`)
  return `${hh}:${mm}`;
}

export const horaMas=(hora)=>{
  console.log(hora)
  const date=new Date(`2020-08-05 ${hora}`);
  console.log(date)
  date.setHours(date.getHours()+1);
  let hh=date.getHours()
  let mm=date.getMinutes();
  hh=formatDate(hh);
  mm=formatDate(mm);
  return `${hh}:${mm}`
}

