import React from 'react';
import './index.css';

const Footer =()=>{
    return(
        <section className="footer">
            <div className="footer-container">
                <div className="footer-container-col-6">
                    <div className="footer-title">Reaseguradora Patria</div>
                    <div className="footer-social-bar">
                        <a href="#" className="social-icon">
                            <img src="/images/facebook-icon.svg" alt="facebook"/>
                        </a>
                        <a href="#" className="social-icon">
                            <img src="/images/twitter-icon.svg" alt="twitter"/>
                        </a>
                        <a href="#" className="social-icon">
                            <img src="/images/linkdin-icon.svg" alt="linkdin"/>
                        </a>
                        <a href="#" className="social-icon">
                            <img src="/images/email-icon.svg" alt="email"/>
                        </a>
                    </div>
                </div>
                <div className="footer-container-col-2">
                    <h4 className="footer-subtitle">Compañia</h4>
                    <a href="#" className="footer-link">Home</a>
                    <a href="#" className="footer-link">Misión y valores</a>
                    <a href="#" className="footer-link">Asociaciones</a>
                    <a href="#" className="footer-link">Sign In</a>
                </div>
                <div className="footer-container-col-2">
                    <h4 className="footer-subtitle">Producto</h4>
                    <a href="#" className="footer-link">Reaseguros</a>
                    <a href="#" className="footer-link">Estado Financieros</a>
                    <a href="#" className="footer-link">Calificaciones</a>
                    <a href="#" className="footer-link">Informe Anual</a>
                </div>
                <div className="footer-container-col-2">
                    <h4 className="footer-subtitle">Legal</h4>
                    <a href="#" className="footer-link">Aviso de Privacidad</a>
                    <a href="#" className="footer-link">Linea de ética</a>
                </div>

            </div>
        </section>
    )
}

export default Footer