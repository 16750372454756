import { useState } from "react";

export default ({ initialState, handlerSubmit }) => {
    const [form, setForm] = useState(initialState);

    const suscribe = (field) => (value) => {
        console.log(field, value);
        setForm({
            ...form,
            [field]: value,
        });
    };

    const onSubmit = () => handlerSubmit();

    return { suscribe, form, setForm, onSubmit };
};
